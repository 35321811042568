import Vue from "vue";
var utils = Vue.mixin({
    methods: {
        leaveLobby() {
            let username = this.$store.state.playerName;
            let userData = this.$store.state.userInfo;
            this.$socket.emit("leave-Lobby", { username, userData }, error => {
                console.log("leave err", error);
            });
            this.$router.push("/gamesdashboard");
        },

        getEventInfoPath(evtCode) {
            console.log("evtCode-",evtCode);
            let eventPath = "/activity-info";
            // switch (evtCode) {
            //     case 'mp':
            //         eventPath = "/magicpictureinfo";
            //         break;
            //     case 'fff':
            //         eventPath = "/fffinfo";
            //         break;
            //     case 'scbl':
            //         eventPath = "/scribbleinfo";
            //         break;

            // }
            return eventPath;
        },
        getEventPath(evtCode) {
            let eventPath = "";
            switch (evtCode) {
                case 'mp':
                    eventPath = "/magicpicture";
                    break;
                case 'fff':
                    eventPath = "/fastestfingerfirst";
                    break;
                case 'scbl':
                    eventPath = "/scriblegame";
                    break;
                case 'customActivity':
                    eventPath = "/CustomActivity";
                    break;

            }
            return eventPath;
        },
        waitForSocketConnection() {
            return new Promise((resolve) => {
                if (this.socketConnected) { // Assuming `socketConnected` is reactive
                    resolve();
                } else {
                    // Polling, event listener, or other logic to wait for connection
                    // This is a simple example using a setTimeout as a stand-in
                    const checkConnection = setInterval(() => {
                        if (this.socketConnected) {
                            clearInterval(checkConnection);
                            resolve();
                        }
                    }, 100); // Check every 100ms
                }
            });
        }
    },
});

export default utils;