<template>
<div class="magicPicturePage">
  <div class="header header-layout-2">
    <div class="header-layout-2-logoSection">
         <img
            class="header-logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="header-logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
      <div class="header-layout-2-middleSection" >
       <div>
         Game code : {{$store.state.gameCode}} 
      </div>
    </div>
    <div class="header-layout-1-rightHeader">
      <button v-if="questionData" class="btn-layout-2 createDatasetBtn leaveGameBtn" @click="leaveLobby">Leave</button>
      <div v-if="!questionData" class="header_nav_link" style="margin-left:20px" @click="$router.push('/homepage')">Home</div>
    </div>
  </div>
  <div class="actvity-layout-1">
    <div class="actvity-layout-1-question-section">
      <div class="question-container"> 
        <div>
          {{questionData}}
        </div>
      </div>
        <div class="optionsSection">
          <button @click="buzzer('A')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">A.{{choices[0].text}}</div></button>
          <button @click="buzzer('B')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">B.{{choices[1].text}}</div></button>
          <button @click="buzzer('C')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">C.{{choices[2].text}}</div></button>
          <button @click="buzzer('D')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">D.{{choices[3].text}}</div></button>
        </div>

      <div  class="">
        <div class="gameControlSection-1" v-if="validateAdmin">
          <div><button :disabled="currentQuestionNo<=1" class="btn-layout-2 color-2 game-btn-1" :class="{ 'disableBtn': currentQuestionNo<=1 }" @click="getPreviousQuestion()"><b-icon icon="chevron-left" font-scale="1" aria-hidden="true"></b-icon></button></div>
          <div><button class="btn-layout-2 color-2" @click="getPicture()">Play</button></div>
          <div><button :disabled="currentQuestionNo>=$store.state.questionLength" :class="{ 'disableBtn': currentQuestionNo>=$store.state.questionLength }" class="btn-layout-2 color-2 game-btn-1" @click="getNextQuestion()"><b-icon icon="chevron-right" font-scale="1" aria-hidden="true"></b-icon></button></div>
        </div>
        
      </div>
      <div class="actvity-layout-1-answerSection" v-if="showAnswer && validateAdmin || showAnswer && showAnswerToAll  || firstBuzzerPressedBy.length>0">
        Answer : {{showAnswer}}
      </div> 
    </div>
    <div class="leaderBoard-section-layout-1" v-if="questionData">
      <div>
        <div v-if="validateAdmin" class="">
          <input @input="searchPlayer($event)" type="text" ref="playerName" class="input-style-2 font-1_2"  placeholder="Search player">
        </div>
      </div>
      
        <div class="leaderBoard-div">
          <div class="leaderBoard-header">
            LeaderBoard
          </div>
        <div class="leaderBoardList">
          <ul class="rankingList">
            <li class="playerRanking" v-for="(val,index) in filteredPlayernamelist" :key="index">
              <div class="playerRank">{{index+1}}.</div>
              <div class="playerName">{{val.name}}</div>
              <div ><b-icon v-if="validateAdmin" @click="decreaseScore(val)" icon="dash-circle" font-scale="1" aria-hidden="true"></b-icon></div>
              <div class="playerScore">{{val.Score}}</div>
              <div ><b-icon v-if="validateAdmin" @click="increaseScore(val)" icon="plus-circle"  font-scale="1" aria-hidden="true"></b-icon></div>
            </li>
          </ul>  
        </div>
      </div>


      <div class="buzzer-div">
          <div v-if="firstBuzzerPressedBy.length>0" class="buzzerpressedby">{{firstBuzzerPressedBy}}</div>
      </div>
    </div>

    <div class="scoreCard-div">
      <div v-if="!validateAdmin" class="scoreCard">
          <div>Your Score</div>
          <div class="t-color-2">{{getScore}}</div>
      </div>
    </div>
  </div>





</div>
</template>

<script>
export default {

   data() {
    return {
      loginplayername:this.$store.state.playerName,
      isAdmin:false,
      playernamelist:[],
      filteredPlayernamelist:[],
      firstBuzzerPressedBy:"",
      currentQuestionNo:false,
      questionData:false,
      choices:[],
      showAnswer:false,
      showAnswerToAll:false,
      wrongAnswer:false,
      playerScore:0,
    };
  },
  created() {
  },
  watch: {
    '$store.state.playerName'(newVal) {
      this.loginplayername = newVal;
    }
  },
  computed: {
    getScore() {
      return this.playerScore;
    },
    validateAdmin(){
      return (this.isAdmin || this.$store.state.superAdmin);
    },
    disableBuzzerBtn(){
      console.log("test : ",this.firstBuzzerPressedBy.length>0,this.wrongAnswer, this.choices.length<=0)
      return this.firstBuzzerPressedBy.length>0 || this.wrongAnswer || this.choices.length<=0;
    }

  },
  mounted() {
   
   this.$socket.on("questionNoFFF", (data) => {
    console.log("qno:",data);
    this.currentQuestionNo=data;
   });
   this.$socket.on("questionFFF", (questionData) => {
    this.questionData=questionData;
   });
   this.$socket.on("playFFF", (choices) => {
    this.choices=choices;
   });
   this.$socket.on("showAnswerFFF", (showAnswer) => {
    this.showAnswer=showAnswer;
   });
   this.$socket.on("showAnswerToAllFFF", (showAnswerToAll) => {
    this.showAnswerToAll=showAnswerToAll;
   });
   this.$socket.on("pressbuzzerByFFF", (data) => {
    this.firstBuzzerPressedBy=data;
   });
   this.$socket.on("resetBuzzerclientFFF", (data) => {
    this.firstBuzzerPressedBy=data;
    this.wrongAnswer=false;
   });

     //lobby
  this.$socket.on("roomData", (data) => {
    this.isAdmin = (data.adminId === localStorage.getItem("user_socket_id"))?true:false;
    let sortedData = data.users.sort(function (a, b) {return b.Score - a.Score;});
    this.playernamelist = this.filteredPlayernamelist = sortedData;
    if(!this.isAdmin)this.updateScore();
   });


  },
  methods: {
    getNextQuestion() {
      this.$socket.emit("nextQuestionFFF", (this.currentQuestionNo + 1));
      this.resetBuzzer();
    },
    getPreviousQuestion() {
      this.$socket.emit("nextQuestionFFF", (this.currentQuestionNo - 1));
      this.resetBuzzer();
    },
    getPicture() {
      this.$socket.emit("playFFF", this.currentQuestionNo);
    },
    buzzer(answer){
      console.log("buzzerpressed")
      if(this.showAnswer === answer){
        this.$socket.emit("pressbuzzerFFF", this.loginplayername);
        this.showAnswerToAll=true;
      }else{
        this.wrongAnswer=true;
        this.showAnswerToAll=true;
      }
    },
    resetBuzzer(){
      this.$socket.emit("resetbuzzerFFF");
      this.wrongAnswer=false;

    },
    showAnswerToAllPlayers(){
      this.$socket.emit("showAnswerToAll");
    },
    increaseScore(playerData){
      let pDetails=playerData;
      pDetails.Score +=100;
      this.$socket.emit("updatePlayerScore",pDetails);
    },
    decreaseScore(playerData){
      let pDetails=playerData;
      pDetails.Score -=100;
      this.$socket.emit("updatePlayerScore",pDetails);
    },
    searchPlayer(event){
    var input = event.target.value;
    let filterpPlayers = this.playernamelist.filter((player) => {
        return (
          player.name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
      this.filteredPlayernamelist = filterpPlayers;
    },
    updateScore() {
       var foundIndex = this.playernamelist.findIndex(
            (player) => player.id == localStorage.getItem("user_socket_id")
        );
        if(this.playernamelist){
          this.playerScore = this.playernamelist[foundIndex].Score;
        }
    },

  }
}
</script>
<style>
.optionItem{
  background: white;
  border: 1px solid var(--primary-color);
  border-radius: 40px;
  min-height: 50%;
  
  font-size: 1.25vw;
  letter-spacing: 1px;
  color: var(--primary-color);
  display: grid;
  align-items: center;
  width: 50%;
  margin: auto;
}
.optionsSection{
  display: grid;
  height: 100%;
  width: 100%;
  padding: 1%;
  text-align: center;
  display: grid;
}
.optionItem:disabled,
.optionItem[disabled]{
  opacity: 0.5 !important;
}
.gameControlSection-1{
  display: grid;
    width: 50%;
    grid-template-columns: 24% 50% 24%;
    margin: auto;
    columns: white;
    gap: 1%;
}
.btn-layout-2{
    border: none;
    color: white;
    font-size: var(--text-lg);
    letter-spacing: 1px;
    font-weight: bold;
    
    border-radius: 50px;
    padding: 1%;
    width: 100%;
    height: 7vh;
}
.font-1_2{
  font-size: 1.2vw !important;
}
</style>
