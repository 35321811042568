<template>
   <div class="create_activity_wrapper">
        <div  class="creatActivity_layout activity_import_layout">
          <div class="creatActivity_left_panel">
            <div
              class="menuItems__questions"
            >
              <div
                v-for="(question, index) in questions"
                :key="question.id"
                :class="[
                  'menuItem',
                  { selected: selectedQuestion == question.id },
                ]"
                @click="selectQuestion(question)"
              >
                <div class="menuItem__Title">
                  <span class="questionTypeIcon"></span>
                  <span class="p-2">
                    <b-form-checkbox
                        :id="question.id"
                        name="checkbox-1"
                        v-model="question.checked"
                        @change="importQuestionChange(question)"
                      >
                      </b-form-checkbox>
                    </span
                  >
                  <span class="menuItem__titleNumber">{{ index + 1 }}</span
                  >.
                  <span class="menuItem__titleText" :title="question.text">{{
                    question.text
                  }}</span>

                </div>
              </div>
            </div>
          </div>
          <div class="creatActivity_right_panel">
            <template v-if="selectedQuestion !== null">
              <QuizInput
                v-if="selectedQuestionType === 'fff'"
                :value="selectedQuestionData"
                :importFlagProp="true"
              />
              <MagicPictureInput
                v-else-if="selectedQuestionType === 'mp'"
                :value="selectedQuestionData"
                :importFlagProp="true"
              />
              <ScribbleInput
              v-else-if="selectedQuestionType === 'scbl'"
                :value="selectedQuestionData"
                :importFlagProp="true"
              />
            </template>
          </div>
        </div>
    </div>
</template>

<script>
import QuizInput from "./fff_input.vue";
import MagicPictureInput from "./mp_input.vue";
import ScribbleInput from "./scribble_input.vue";
export default {
 name:"activity_import_layout",
 components:{
    QuizInput,
    MagicPictureInput,
    ScribbleInput
 },

  props: {
    questionsProp: Array,
    importQuestions:Array
  },
  data() {
    return {
      questions: this.questionsProp || [],
      selectedQuestion:0,
      selectedQuestionType:'',
      selectedQuestionData:{}
    };
  },
//   watch: {
//     value: {
//       immediate: true,
//       handler(newValue) {
//         this.file = newValue.file || "";
//         this.questionText = newValue.questionText || "";
//         this.answer = newValue.answer || '';
//         this.id = this.value.id || "";
//         if (newValue.file) {
//           this.loadFile(newValue.file);
//         } else {
//           this.imageUrl = "";
//         }
//       },
//     },
//   },

   mounted(){
    if(this.questions.length>0){
        this.selectedQuestion=this.questions[0].id;
        this.selectedQuestionType=this.questions[0].type;
        this.selectedQuestionData=this.questions[0];
        this.loadSeletedQuestions();
    }
  },
  methods: {
    loadSeletedQuestions(){
      for(let i=0;i<this.questions.length;i++){
        for(let j=0;j<this.importQuestions.length;j++){
          if(this.questions[i].id===this.importQuestions[j].id){
            this.questions[i]=this.importQuestions[j];
          }
        }
      }
    },
    selectQuestion(question) {
      this.selectedQuestion = question.id;
      this.selectedQuestionType = question.type;
      this.selectedQuestionData = question;

    },
    importQuestionChange(question) {
      if(question.checked){
        this.$emit("addImportQuestion", question);
      }else{
        this.$emit("removeImportQuestion", question);
      }
    },
  }
};
</script>


<style>
.activity_import_layout{
    height: 67vh !important;
}
</style>