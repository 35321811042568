<template>
  <div class="homePage">
    <div class="homePageBody">
      <div class="trending-slides defaultLM">
        <VueSlickCarousel v-bind="settings" class="slides">
          <div
            class="slideItem"
            v-for="data in slidesData"
            :key="data.eventUrl"
            @click="selectedSlide(data)"
          >
            <div class="slideCard">
              <div class="slideCardImg">
                <img
                  class="slideImg"
                  :src="require(`../assets/${data.imgUrl}.png`)"
                />
              </div>
              <div class="slideCardDetails">
                <div class="slideCardTitle gameTitle">{{ data.title }}</div>
                <div class="slideCardInfo gameAbout">{{ data.details }}</div>
                <div class="slideCardCategory gameAbout">
                  <div class="categoryWord">Category:</div>
                  <div class="categoryType">{{ data.category }}</div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>

      <div class="defaultLM">
        <div class="SectionTitle">Popular this Month</div>
        <div class="sectionContainer">
          <div class="grid activity_list_Items">
            <fragment v-for="event in filteredEventList" :key="event.data_code">
              <div
                class="item_card shadow border-radius-md border card-1 m-auto"
                @click="gotoEventPage(event)"
                :title="event.name"
              >
                <div class="cardThumbnail-cointainer">
                  <img
                    class="cardThumbnail"
                    style=""
                    :src="event.preview_url"
                  />
                </div>

                <div class="card-body p-2">
                  <div class="videoTitle">{{ event.name }}</div>
                  <div class="videoBy">Create By: {{ event.Created_By }}</div>
                  <div class="videoDetails">
                    <div class="videoDuration">
                      Event Type: {{ event.Event_Type }}
                    </div>
                    <!-- <span class="dot"></span>
                   <div class="videoUploadDate">{{event.uploadDate}}</div> -->
                  </div>
                </div>
              </div>
            </fragment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { feedbackDetails } from "../api/index";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
export default {
  data() {
    return {
      containerHeight: 0,
      slidesData: [
        {
          title: "Scribble",
          details:
            "Lets see how quickly you can guess the word from the drawing.",
          category: "Fun",
          eventUrl: "/scribbleinfo",
          imgUrl: "scribble2",
          uploadDate: "1 day ago",
          data_code: "teamsmagic_scbl_001",
          Event_Code: "scbl",
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/scribble2.png",
          name: "Scribble",
          Event_Type: "Scribble",
          Created_By: "TeamsMagic",
        },
        {
          title: "The Magic Picture",
          details: "Lets see how strong your IQ is from Solving the Mystery.",
          category: "Fun",
          eventUrl: "/magicpictureinfo",
          imgUrl: "magicPicture",
          uploadDate: "7 days ago",
          data_code: "teamsmagic_mp_001",
          Event_Code: "mp",
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/magicPicture.png",
          name: "The Magic Picture",
          Created_By: "TeamsMagic",
          Event_Type: "Magic Picture",
        },
        {
          title: "Meditation Sessions",
          details:
            "Lets achieve inner peace with Musical and Guided meditation session.",
          category: "Meditation",
          eventUrl: "",
          imgUrl: "meditationLogo1",
        },
        {
          title: "Fastest Finger First",
          details: "Lets see how strong your GK is from Answering simple Quiz.",
          category: "Fun",
          eventUrl: "/fffinfo",
          evtCode: "fff",
          imgUrl: "quiz_game",
          uploadDate: "5 days ago",
          data_code: "teamsmagic_fff_001",
          Event_Code: "fff",
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/quiz_game.png",
          name: "Fastest Finger First",
          Event_Type: "Fastest Finger First",
          Created_By: "TeamsMagic",
        },
      ],

      settings: {
        // autoplaySpeed: 2000,
        // pauseOnFocus: true,
        pauseOnHover: false,
        arrows: true,
        // edgeFriction: 0.35,
        // infinite: true,
        // speed: 1000,
        // slidesToShow: 1,
        // slidesToScroll: 1,

        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1500,
      },

      currentYear: false,
      showJoin: false,
      loading: false,
      isJoinInvalid: false,
      joinErrMsg: "",
      filteredEventList: [
        {
          Id: 1019,
          data_code: "teamsmagic91858",
          name: "Wrong Answers Only",
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic91858/1721807098359848456.jpeg",
          Created_By: "TeamsMagic",
          Event_Type: "Custom",
          Event_Code: "-",
          Category: "Entertainment",
          Market_Display_Flg: "Y",
          Sort_Order: 1,
          Publish_Type: "public",
          Org_Name: "TeamsMagic",
        },
        {
          Id: 1015,
          data_code: "teamsmagic6236",
          name: "Around the House Scavenger Hunt",
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic6236/scavenger_thumbnail.jpeg",
          Created_By: "TeamsMagic",
          Event_Type: "Magic Picture",
          Event_Code: "mp",
          Category: "Entertainment",
          Market_Display_Flg: "Y",
          Sort_Order: 1,
          Publish_Type: "public",
          Org_Name: "TeamsMagic",
        },
        {
          Id: 1014,
          data_code: "teamsmagic6180",
          name: "Icebreaker : Uncovering Connections",
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic6180/1718961972611036822.jpeg",
          Created_By: "TeamsMagic",
          Event_Type: "Magic Picture",
          Event_Code: "mp",
          Category: "Entertainment",
          Market_Display_Flg: "Y",
          Sort_Order: 1,
          Publish_Type: "public",
          Org_Name: "TeamsMagic",
        },
        {
            "Id": 1011,
            "data_code": "teamsmagic54740",
            "name": "Worldly Wonders Quiz",
            "preview_url": "https://teamsmagicdevstorage.blob.core.windows.net/fff-container/1714903115253223987.jpeg",
            "Created_By": "TeamsMagic",
            "Event_Type": "Fastest Finger First",
            "Event_Code": "fff",
            "Category": "Entertainment",
            "Market_Display_Flg": "Y",
            "Sort_Order": 1,
            "Publish_Type": "public",
            "Org_Name": "TeamsMagic"
        }
      ],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    console.log("loggeduser => " + localStorage.getItem("user_socket_ID"));
    console.log("heighttt:  ", document.documentElement.clientHeight);
    console.log(
      "header heighttt:  ",
      document.getElementsByClassName("header")[0].offsetHeight
    );
    this.containerHeight =
      document.documentElement.clientHeight -
      document.getElementsByClassName("header")[0].offsetHeight;
    const d = new Date();
    this.currentYear = d.getFullYear();

    //join Event
    this.$socket.on("joined", (data) => {
      if (data) {
        this.$store.commit("setGameCode", data.gameCode);
        this.$store.commit("setQuestionLength", data.questionLength);
        this.$store.commit("setGameConfig", data.gameConfig);
        let path = this.getEventPath(data.evtCode);
        this.$router.push(path);
      }
    });
  },
  methods: {
    gotoEventPage(event) {
      let path = this.getEventInfoPath(event.Event_Code);
      this.$store.commit("setEventData", event);
      this.$router.push(path);
    },
    showJoinModal() {
      this.showJoin = true;
    },
    handleJoinRoom(data) {
      this.loading = true;
      let username = data.userName;
      let room = data.roomCode;
      let userData = this.$store.state.userInfo;
      let eventCode = "";
      this.$socket.emit(
        "join",
        { username, room, eventCode, userData },
        (error) => {
          console.log("Join err", error);
          if (error) {
            this.isJoinInvalid = true;
            this.joinErrMsg = error;
          } else {
            this.$store.commit("setPlayerName", username);
          }
          this.loading = false;
        }
      );
    },
    gotoMagicPicture() {
      this.$router.push("/magicpicture");
    },
    selectedSlide(data) {
      if (data.category == "Fun") {
        this.$store.commit("setEventData", data);
        //this.$router.push(data.eventUrl);
        this.$router.push("/activity-info");
      } else {
        this.$router.push("/meditation");
      }
    },
    sendFeedback() {
      let data = {
        name: this.$refs.feedbackName.value
          ? this.$refs.feedbackName.value
          : this.$store.state.playerName,
        email: this.$refs.feedbackEmail.value,
        msg_body: this.$refs.feedbackBody.value,
        phone: "",
      };
      feedbackDetails(data)
        .then((resp) => {
          console.log("list:", resp);
          this.resetFeedback();
        })
        .catch((err) => {
          console.log("Error", err);
          this.resetFeedback();
        });
    },
    resetFeedback() {
      this.$refs.feedbackName.value = "";
      this.$refs.feedbackEmail.value = "";
      this.$refs.feedbackBody.value = "";
    },
  },
};
</script>
<style>
.homePage {
  width: 100%;
}
.homePageBody {
  width: 100%;
  background: #f8f8f8;
  min-height: calc(100vh - 75px);
}
.rightHearder {
  right: 1%;
  position: absolute;
  font-size: 14pt;
  color: var(--primary-color);

  margin-top: 38px;
  letter-spacing: 1px;
}
.trending-slides {
}
.hero-container,
.hero-container-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5%;
  height: 100vh;
  align-items: center;
}

.hero-container-1 {
  grid-template-columns: repeat(1, 1fr);
}
.header-logoIcon {
  height: 100%;
}
.header-logoImg {
  height: 100%;
}

.hero-section-logo-div {
  width: 36vw;
  height: 36vw;
  text-align: center;
  display: grid;
  align-items: center;
  border-radius: 50%;
  margin: auto;
  background: radial-gradient(var(--primary-color) 50%, white 75%);
}
.rg-cl-2 {
  background: radial-gradient(#b8df10 50%, white 75%) !important;
}
.hero-section-logo {
  width: 75%;
  border-radius: 15%;
  margin: auto;
}
.gameTitle {
  font-size: var(--text-3xl);
  color: var(--primary-color);
  font-weight: bold;
}
.gameAbout,
.details-section-about {
  font-size: var(--text-lg);
  color: var(--primary-color);
  /* font-weight: bold; */

  margin-top: 15px;
  letter-spacing: 1px;
}
.details-section-about {
  margin: 0px;
}
.gameinfo {
  margin-top: 135px;
  margin-left: 100px;
  width: 510px;
}
.gamelogo {
  background-image: url("../assets/circleDesign.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 650px 550px;
  width: 650px;
  height: 550px;
}
/* .meditationInfo{
  width: 650px !important;
  margin-left: 0px !important;
} */
.meditationlogo {
  background-image: url("../assets/circleDesign4.png") !important;
  margin-left: 100px !important;
}

.footer {
  height: 400px;
  background-color: var(--primary-color);
}
.footerBody {
  display: flex;
}
.footerLogo {
  margin-top: 75px;
}
.feedbackSection {
  margin-left: 300px;
  margin-top: 10px;
  width: 550px;
}
.feedbackTitle {
  color: white;

  font-size: x-large;
}
.feedbackName {
  display: grid;
  grid-template-columns: 49% 49%;
  align-items: center;
  gap: 2%;
}
.feedbackDescription {
  height: 100%;
}

.footer-feedback {
  display: grid;
  grid-template-rows: 16% 59% 17%;
  align-items: center;
  height: 100%;
  gap: 4%;
  width: 65%;
  margin: auto;
}
.copyrightSection {
  text-align: center;
  width: 100%;
  color: white;
  margin-top: 15px;
}
.slideItem {
  width: 95% !important;
  height: 92%;
  /* border: 1px solid var(--primary-color); */
  background-color: #f0f8ff;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  /* box-shadow: 0 0 15px -2px #444444; */
  box-shadow: 4px 4px 7px 2px rgba(0, 0, 0, 0.4);
}
.slides {
  text-align: center;
  width: 100%;
  height: 100%;
}
.slideCard {
  display: grid;
  grid-template-columns: 50% 50%;
}
.slideImg {
  height: 300px;
  width: 100%;
  border-radius: 15px;
}
.slideCardDetails,
.details-section {
  height: 100%;
  width: 100%;
  padding: 5%;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4%;
}

.details-section-infoBtn {
  width: 50%;
}
.slideCardCategory {
  display: flex;
  margin: 0px !important;
}
.categoryType {
  margin-left: 10px;
  color: var(--primary-color);
  font-weight: bold;
}
.categoryWord {
  color: #000000 !important;
}
.footerAbout {
  margin-left: 100px !important;
  width: 484px;
}
.slideCardInfo {
  margin: 0px !important;
}
.slick-prev:before {
  content: "←";
}
.slick-next {
  right: 0;
}
.slick-prev {
  left: 0;
}
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  color: var(--primary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  font-weight: 300;
  z-index: 1;
}
.header_nav_link {
  display: flex;
  cursor: pointer;
}

.header-layout-1,
.header-layout-2 {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 1%;
  width: 100%;
  height: 10vh;
  align-items: center;
  position: fixed;
  z-index: 500;
  background: white;
}
.header-layout-2 {
  grid-template-columns: 30% 50% 20%;
}
.header-layout-1-logoSection,
.header-layout-2-logoSection {
  width: 100%;
  height: 100%;
  padding-left: 3%;
  display: flex;
  height: 5vh;
  align-items: center;
  gap: 1%;
}
.header-layout-2-logoSection {
  padding-left: 6%;
}
.header-layout-2-middleSection {
  justify-content: right;
}
.header-layout-1-rightHeader {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 1.25vw;
  color: var(--primary-color);

  letter-spacing: 1px;
}
.homepage-footer {
  height: 50vh;
  width: 100%;
  display: grid;
  align-items: center;
  background: var(--primary-color);
  grid-template-rows: 84% 7%;
  padding: 2%;
  gap: 9%;
}
.footer-body {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 48%;
  height: 100%;
}
.footer-copyright {
  text-align: center;
  color: white;
}
.footer-tm-log {
  width: 100%;
}
.font-style-1 {
  font-size: 1vw;

  letter-spacing: 1px;
}
.feedback-textArea {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 2%;
}
.color-3 {
  color: #ffffff;
}
.footer-logo {
  width: 72%;
  margin: auto;
}
.disableBtn {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
