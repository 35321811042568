<template>
<div class="homePage">
  <div class="header">
    <div class="logoSection">
         <img
            class="logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
    <div class="rightHearder" style="display:flex">
      <div class="header_nav_link">
      Live Events
       <div>
        <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
        </div>
      </div>
      <div class="header_nav_link" style="margin-left:20px">
      Rewards
       <div>
        <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
        </div>
      </div>
      <div class="header_nav_link" style="margin-left:20px" @click="$router.push('/homepage')">Home</div>
    </div>
  </div>
  <div class="homePageBody">
    <div class="gameDetails" 
    style= "height: 90vh">
      <div class="gamelogo evtPageLogo">
        <img
            class="logobg"
            :src="require( `../assets/${data.imgUrl}.png`)"
        />
      </div>
      <div class="gameinfo evtPageInfo">
        <div class="gameTitle">
          {{data.title}}
        </div>
        <div class="gameAbout">
          {{data.details}}
        </div>
        <div class="slideCardCategory gameAbout" >
              <div class="categoryWord">Category: </div>
              <div class="categoryType">{{data.category}}</div>
          </div>
        <div class="slideCardCategory gameAbout" >
              <div class="categoryWord">Uploaded: </div>
              <div class="categoryType">{{data.uploadDate}}</div>
          </div>
        <div class="gameJoinBtn">
            <button class="defaultbtn" style="margin-left:0px;width:100%" @click="createLobby">Create Private Room</button>
            <input class="loginInput" style="width: 50%;" type="text" ref="loobyCode" placeholder="Enter Code">
            <button class="defaultbtn" style="margin-left:0px;width:50%" @click="joinLobby">Join Room</button>
          <!-- <button class="defaultbtn" style="margin-left:0px" @click="$router.push(data.eventUrl)">Join Game</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data(){
    return{
        data:this.$store.state.eventData,

        }
  },
    created() {
    console.log("event",this.$socket)

  },
  mounted(){
    this.$socket.on("created", (data) => {
    if(data){
      this.$store.commit("setGameCode", data.gameCode);
      this.$store.commit("setQuestionLength", data.questionLength);
      this.$router.push(this.data.eventUrl);
    }
   });
    this.$socket.on("connect_error", (err) => {
      console.log("connect_error",err.message); // prints the message associated with the error
    });
    this.$socket.on("error", (err) => {
      console.log("Error",err.message); // prints the message associated with the error
    });
    this.$socket.on("joined", (data) => {
    if(data){
      this.$store.commit("setGameCode", data.gameCode);
      this.$store.commit("setQuestionLength", data.questionLength);
      this.$store.commit("setGameConfig", data.gameConfig);
      this.$router.push(this.data.eventUrl);
    }
   });

  },
    methods:{
    createLobby(){
      let username=this.$store.state.playerName;
      let evtCode=this.data.evtCode;
       this.$socket.emit("create",{ username,evtCode}, error => {
        console.log("create err",error);
       });
     },
     joinLobby(){
      let username=this.$store.state.playerName;
      let room =this.$refs.loobyCode.value;
      let evtCode=this.data.evtCode;
       this.$socket.emit("join",{ username, room ,evtCode}, error => {
        console.log("Join err",error);
       });
     }
  }
}
</script>
<style>
.evtPageLogo{
  margin-top: 50px !important;
}
.evtPageInfo{
  margin-top: 50px !important;
}
</style>
