<template>
  <div class="Home">
    <!--This Header needs changed completely  -->
    <div
      class="header-layout-1"
    >
      <div class="header-layout-1-logoSection home-logo-header" @click="$router.push('/')">
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img class="header-logoImg" src="../assets/loginPageLogo.png" />
      </div>
    </div>

    <!-- page starts -->

    <div class="home-body">
      <div class="privacy-container">
            <h2>Privacy Policy</h2>
        <p>
          At Teamsmagic, we are committed to protecting your privacy and ensuring the security of your personal information.
          This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when using our Software-as-a-Service (SaaS) platform and visiting our landing page.
          By accessing or using our services, you agree to the terms of this Privacy Policy.
        </p>

        <h3>1. Information We Collect</h3>
        <h4>1.1 Account Creation and Registration</h4>
        <p>
          When you sign up for an account on our SaaS platform, we collect the following information:
          <ul>
            <li>Full name</li>
            <li>Email address</li>
            <li>Company name</li>
            <li>Job title</li>
            <li>Password (stored securely in encrypted form)</li>
          </ul>
        </p>

        <h4>1.2 Landing Page</h4>
        <p>
          When you visit our landing page, we may collect the following information:
          <ul>
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Referring website</li>
            <li>Pages visited and the time spent on each page</li>
            <li>Any information voluntarily provided through forms or other means</li>
          </ul>
        </p>

        <h3>2. Use of Information</h3>
        <h4>2.1 Account Information</h4>
        <p>
          We use the information you provide during account registration to:
          <ul>
            <li>Set up and manage your account</li>
            <li>Communicate with you regarding your account, updates, and support</li>
            <li>Provide personalized features and services</li>
            <li>Analyze usage patterns to improve our services</li>
            <li>Comply with legal obligations</li>
          </ul>
        </p>

        <h4>2.2 Landing Page Information</h4>
        <p>
          We use the information collected through our landing page to:
          <ul>
            <li>Improve our website's functionality and user experience</li>
            <li>Analyze visitor trends and patterns</li>
            <li>Monitor and enhance website security</li>
            <li>Respond to inquiries and fulfill requests made through contact forms</li>
          </ul>
        </p>
        <h3>3. Information Sharing</h3>
        <p>
        We do not sell, rent, or trade your personal information to third parties for marketing purposes.
        However, we may share your information in the following circumstances:
        <ul>
            <li>With your consent or at your direction</li>
            <li>With service providers who assist us in providing our services (e.g., hosting, analytics)</li>
            <li>To comply with legal obligations, such as responding to a court order or government request</li>
            <li>In connection with a merger, acquisition, or sale of all or a portion of our assets</li>
        </ul>
        </p>

        <h3>4. Data Security</h3>
        <p>
        We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.
        These measures include encryption, access controls, and regular security assessments.
        However, no method of data transmission or storage is completely secure, and we cannot guarantee absolute security.
        </p>

        <h3>5. Third-Party Links</h3>
        <p>
        Our SaaS platform and landing page may contain links to third-party websites.
        We are not responsible for the privacy practices or content of these websites.
        We encourage you to review the privacy policies of those third parties before providing any personal information.
        </p>

        <h3>6. Children's Privacy</h3>
        <p>
        Our services are not directed to individuals under the age of 16.
        We do not knowingly collect or solicit personal information from children.
        If we learn that we have collected personal information from a child under the age of 16, we will promptly delete it.
        If you believe that a child under the age of 16 has provided us with personal information, please contact us at the email address provided below.
        </p>

        <h3>7. Your Rights</h3>
        <p>
        You have the right to access, correct, update, or delete your personal information, subject to legal limitations.
        If you wish to exercise any of these rights or have any questions or concerns about our Privacy Policy, please contact us using the contact information below.
        </p>

        <h3>8. Changes to this Privacy Policy</h3>
        <p>
        We reserve the right to modify this Privacy Policy at any time.
        Any changes will be effective immediately upon posting the updated Privacy Policy on our website.
        Your continued use of our services after the posting of any revised Privacy Policy constitutes your acceptance of such changes.
        </p>

        <h3>9. Contact Us</h3>
        <p>
        If you have any questions, comments, or concerns about our Privacy Policy or our privacy practices, please contact us at support@teamsmagic.com
        We will strive to respond to your inquiries and resolve any concerns in a timely manner.
        </p>

        <p>
        By using our SaaS platform and visiting our landing page, you acknowledge that you have read and understood this Privacy Policy.
        You also agree to our collection, use, and disclosure of your personal information as described herein.
        </p>

        <p>
        Please note that this Privacy Policy applies solely to the information collected by our SaaS platform and landing page and does not cover any other interactions or services provided by third parties or affiliates that may be linked to or integrated with our platform.
        </p>

        <p>
        Thank you for trusting Teamsmagic with your personal information.
        We are committed to protecting your privacy and providing you with a secure and reliable experience.
        </p>

      </div>

      <div class="home-footer">
        <div class="home-footer-body">
          <div class="home-footer-logo-div">
            <img class="footer-tm-log" src="../assets/logoWhite.png" />
          </div>
          <div class="home-footer-about">
            A virtual platform provides employee engagement with customised
            online engagement programmes for your teams, worldwide.
          </div>
        </div>
        <div class="copywirte-div">
          © {{ currentYear }} TeamsMagic. All Rights Reserved.
        </div>
      </div>
    </div>
    <b-modal
      id="default-modal"
      ref="default-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title=""
    >
      <p class="my-4">{{ modalMsg }}</p>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style>
.privacy-container {
  display: grid;
  padding: 5%;
  align-items: center;
  height: 100%;
  padding-top: 10vh;
}
</style>
