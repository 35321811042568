<template>
 <div class="homePage">
   <BlockUI v-if="loading" :message="loadingMsg">
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
  </BlockUI>

  <div class="homePageBody">
        <div class="musicalSection defaultLM p-1">
            <div class="SectionTitle d-flex">
                <div class="serchBarSection">
                   <div role="group" class="input-group" ><!---->
                   
                        <input id="bv-icons-table-search" v-model="searchValue" type="search" autocomplete="off" class="form-control" placeholder="Search...">
                        <div class="input-group-prepend" @click="search">
                            <div class="input-group-text">
                                <b-icon icon="search" scale="1" ></b-icon>
                            </div>
                         </div> 
                    </div>
                </div>
                
                <div class="category-div">
                    Category :
                    <b-dropdown id="dropdown-1" :text="selectedCategory" class="m-md-2 category-dropdown">
                        <b-dropdown-item @click="setCategory('All')">All</b-dropdown-item>
                        <b-dropdown-item @click="setCategory('Entertainment')">Entertainment</b-dropdown-item>
                        <b-dropdown-item @click="setCategory('Training')">Training</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="sectionContainer" >
               

             <div class="grid activity_list_Items" >
             
              <fragment 
                v-for="event in filteredEventList"
                :key="event.data_code">
                <div
                class="item_card border-radius-md border card-1 m-auto shadow"
                @click="gotoEventPage(event)"
                :title="event.name"
                >
                <div class="cardThumbnail-cointainer">
                    <img
                        class="cardThumbnail"
                        style=""
                        :src="event.preview_url"
                        />
                </div>
             
                <div class="card-body p-2">
                    <div class="videoTitle">{{event.name}}</div>
                    <div class="videoBy">Create By: {{event.Created_By}}</div>
                    <div class="videoDetails">
                    <div class="videoDuration">Event Type: {{event.Event_Type}}</div>
                    <!-- <span class="dot"></span>
                    <div class="videoUploadDate">{{event.uploadDate}}</div> -->
                </div>
                </div>
            
                </div>
                </fragment>
            </div>
             <infinite-loading
                     @infinite="infiniteHandler"
                     :identifier="infiniteId"
                    >
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
        </div>



   
  </div>
    

    <b-modal id="error-modal" ref="error-modal" size= 'sm'  buttonSize= 'sm'  headerClass='p-2 border-bottom-0'  footerClass='p-2 border-top-0' hide-header-close  ok-only centered title="Oops.">
    <p class="my-4">{{errMsg}}. Please try again</p>
    </b-modal>

</div>
</template>

<script>
import {createMPEventDataset,getEventDetails,createScblEventDataset,createFFFEventDataset} from "../api/index"
export default {
     data(){
    return{
        loadingMsg:"Please wait",
        loading:false,
        tabIndex:1,
        eventTypeList:[
            {
                eventName:"Magic Picture",
                eventCode:"mp",
            },
            {
                eventName:"Fastest Finger First",
                eventCode:"fff",
            },
            {
                eventName:"Scribble",
                eventCode:"scbl",
            }
            ],

        eventDetails:{
            Title:"",
            Created_By:"",
            Event_Type:"",
            Event_Code:"",
            Preview_Url:"",
            data_code:"",
        },
        previewFile:false,
        previewFileUrl:false,
        selectedFileUrl:false,
        dataSet:[],
        filesData:[],
        currentIndex:0,
        infiniteId: +new Date(),

        //for  categroy dropdown
        selectedCategory:"All",
        
        //for display events
        eventList:[],
        filteredEventList:[],
        

        //seachVar
        searchValue:"",

        //MP
        files:[],
        Question:"",
        Answer:"",

        ///FFF
        fffOptions: [
          { item: 'A', name: 'A' },
          { item: 'B', name: 'B' },
          { item: 'C', name: 'C'},
          { item: 'D', name: 'D' }
        ],
        fffOptionA:"",
        fffOptionB:"",
        fffOptionC:"",
        fffOptionD:"",
        fffAnswer:"",
        fffQuestion:"",


        //scrible
        scblWord:"",
       
       
       errMsg:"",
    }
    },
     mounted() {
        //     console.log("this.dataSet.length;;",this.dataSet.length)

        // this.getEventData();
     },
     computed:{
        disableFinishBtn(){
            return (this.dataSet.length === 0)?true:false;
        }
     },

    methods:{
        infiniteHandler($state){
            this.loading=true;
            getEventDetails()
                .then((resp) => {
                this.eventList= this.filteredEventList=resp.data.data;
                this.loading=false;
                $state.complete();

                })
                .catch((err) => {
                console.log("Error",err.response.data);
                this.loading=false;
                $state.complete();
                });
        },

        search(){
            let seachWord = this.searchValue.toLowerCase();
            let checkCategory= this.selectedCategory!="All";
            this.filteredEventList = this.eventList.filter((item)=> {
                let title = item.name.toLowerCase();
                if(checkCategory){
                    if(item.Category == this.selectedCategory && title.includes(seachWord))
                    return true;
                    else
                    return false;
                }else{
                    if(title.includes(seachWord))
                    return true;
                    else
                    return false;
                }

            }
        )
        },

        setCategory(value){
            this.selectedCategory = value;
            this.search();
        },

        setEventType(event){
            this.eventDetails.Event_Type=event.eventName;
            this.eventDetails.Event_Code=event.eventCode;
            this.dataSet=[];
            this.filesData=[];
            this.currentIndex=0;
        },

        gotoEventPage(event){
            let path=this.getEventInfoPath(event.Event_Code);
            this.$store.commit("setEventData", event);
            this.$router.push(path);
        },

        completeResetModal(){
            this.resetModal();
            
            this.eventDetails={
                Title:"",
                Created_By:"",
                Event_Type:"",
                Event_Code:"",
                Preview_Url:"",
                data_code:"",
            };
            this.dataSet=[];
            this.previewFile=false;
            this.previewFileUrl=false;
            this.currentIndex=0;

            if(this.$refs.previewfile){
            this.$refs.previewfile.value = null;
            }

            
        },
        resetModal(){
            switch (this.eventDetails.Event_Code) {
                case 'mp':
                     //RESET MP
                    this.files=[];
                    if(this.$refs.files){
                    this.$refs.files.value = null;
                    }
                    this.Question="";
                    this.Answer="";
                    this.selectedFileUrl=false;
                    break;
                case 'fff':
                    //RESET FFF
                    this.fffOptionA="";
                    this.fffOptionB="";
                    this.fffOptionC="";
                    this.fffOptionD="";
                    this.fffAnswer="";
                    this.fffQuestion="";
                    break;
                case 'scbl':
                    //RESET Scribble
                    this.scblWord="";
                    break;
            }



        },
        setSelectedFileUrl(files){
            console.log("setSelectedFileUrl:",files)
            if (files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                this.selectedFileUrl = e.target.result;
                }
                reader.readAsDataURL(files[0]);
            }
        },
        loadModalData(){
            switch (this.eventDetails.Event_Code) {
                case 'mp':
                    this.Question=this.dataSet[this.currentIndex].Question;
                    this.Answer=this.dataSet[this.currentIndex].Answer;
                    this.files =[this.filesData[this.currentIndex]];
                    this.setSelectedFileUrl(this.files);
                    break;
                case 'fff':
                    this.fffQuestion=this.dataSet[this.currentIndex].question;
                    this.fffAnswer=this.dataSet[this.currentIndex].answer;
                    this.fffOptionA=this.dataSet[this.currentIndex].choices[0];
                    this.fffOptionB=this.dataSet[this.currentIndex].choices[1];
                    this.fffOptionC=this.dataSet[this.currentIndex].choices[2];
                    this.fffOptionD=this.dataSet[this.currentIndex].choices[3];
                    break;
                case 'scbl':
                    this.scblWord=this.dataSet[this.currentIndex];
                    break;
            }
        },

        prevQuestion(){
            this.currentIndex-=1;
            this.resetModal();
            this.loadModalData();
        },
        nextQuestion(){
            this.currentIndex+=1;
            this.resetModal();
            this.loadModalData();
        },
        newQuestion(){
            this.currentIndex=this.dataSet.length;
            this.resetModal();
        },
        addMPData(){
            let obj={
                Question:this.Question,
                Answer:this.Answer,
                Question_No:this.currentIndex+1,
                Event_Name:"mp",
                Question_Url: "",
                data_code: "teamsmagic_mp_001",
            }
            let file=this.files;
            this.dataSet[this.currentIndex]=obj;
            this.filesData[this.currentIndex]=file;
            console.log("dataset:",this.dataSet,this.filesData);
        },
        addFFFData(){
            let obj={
                question:this.fffQuestion,
                answer:this.fffAnswer,
                questionNo:this.currentIndex+1,
                choices:[this.fffOptionA,this.fffOptionB,this.fffOptionC,this.fffOptionD],
                Event_Name:"fff",
                data_code: "teamsmagic_fff_001",
            }

            this.dataSet[this.currentIndex]=obj;
            console.log("dataset:",this.dataSet);
        },
        addScblData(){
            this.dataSet[this.currentIndex]=this.scblWord;
            console.log("dataset:",this.dataSet);
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
         getFiles(e) {
            // this.files=Array.from(e.target.files);
            var input = e.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                this.selectedFileUrl = e.target.result;
                }
                this.files=input.files[0];
               
                reader.readAsDataURL(input.files[0]);
            }
         },
         getPreviewFile(e) {
            var input = e.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                this.previewFileUrl = e.target.result;
                }
                this.previewFile=input.files[0];
                reader.readAsDataURL(input.files[0]);
                 console.log("previewFile:",this.previewFile);
            }
         },
        createDataset(){
             switch (this.eventDetails.Event_Code) {
                case 'mp':
                    this.createMPDataset();
                    break;
                case 'fff':
                    this.createFFFDataset();
                    break;
                case 'scbl':
                    this.createScblDataset();
                    break;
            }
        },
        createMPDataset(){
            this.loading=true;
            let randomnum=Math.floor(Math.random() * 100000);
            let dataCodeName = this.eventDetails.Created_By.toLowerCase().split(" ")[0];
            var regexPattern = /[^A-Za-z0-9]/g;
            var ans = dataCodeName.replace(regexPattern, "");
            this.eventDetails.data_code= ans+randomnum.toString();
            let data = new FormData();
            data.append('eventDetails', JSON.stringify(this.eventDetails));
            data.append('dataset', JSON.stringify(this.dataSet));
            data.append('previewfile', this.previewFile);
            for (var i = 0; i < this.filesData.length; i++) {
                let file = this.filesData[i];
                data.append("file", file);
            }
            createMPEventDataset(data)
                .then((resp) => {
                console.log("resp",resp);
                this.completeResetModal();
                this.hideModal();
                this.loading=false;
                this.infiniteId = +new Date();
                })
                .catch((err) => {
                console.log("Error",err);
                this.completeResetModal();
                this.hideModal();
                this.loading=false;
                this.infiniteId = +new Date();
                });
          
        },
        createFFFDataset(){
            this.loading=true;
            let randomnum=Math.floor(Math.random() * 100000);
            let dataCodeName = this.eventDetails.Created_By.toLowerCase().split(" ")[0];
            var regexPattern = /[^A-Za-z0-9]/g;
            var ans = dataCodeName.replace(regexPattern, "");
            this.eventDetails.data_code= ans+randomnum.toString();
            // this.eventDetails.data_code= "teamsmagic_fff_001";
            let data = new FormData();
            data.append('eventDetails', JSON.stringify(this.eventDetails));
            data.append('dataset', JSON.stringify(this.dataSet));
            data.append('previewfile', this.previewFile);

            createFFFEventDataset(data)
                .then((resp) => {
                console.log("resp",resp);
                this.completeResetModal();
                this.hideModal();
                this.loading=false;
                this.infiniteId = +new Date();
                })
                .catch((err) => {
                console.log("Error",err);
                  this.completeResetModal();
                this.hideModal();
                this.loading=false;
                this.infiniteId = +new Date();
                });
          
        },
        createScblDataset(){
            this.loading=true;
            let randomnum=Math.floor(Math.random() * 100000);
            let dataCodeName = this.eventDetails.Created_By.toLowerCase().split(" ")[0];
            var regexPattern = /[^A-Za-z0-9]/g;
            var ans = dataCodeName.replace(regexPattern, "");
            this.eventDetails.data_code= ans+randomnum.toString();
            // this.eventDetails.data_code= "teamsmagic-scbl-002";
            let data = new FormData();
            data.append('eventDetails', JSON.stringify(this.eventDetails));
            data.append('dataset', JSON.stringify(this.dataSet));
            data.append('previewfile', this.previewFile);

            createScblEventDataset(data)
                .then((resp) => {
                console.log("resp",resp);
                this.completeResetModal();
                this.hideModal();
                this.loading=false;
                this.infiniteId = +new Date();
                })
                .catch((err) => {
                console.log("Error",err);
                this.completeResetModal();
                this.hideModal();
                this.loading=false;
                this.infiniteId = +new Date();
                });
          
        },
        handleCreateDataset(){
            this.$router.push("/create-activity");
        }
    }

}
</script>

<style>
.createDatasetBtnSection,.header-layout-2-middleSection{
    font-size: 1.25vw;
    color: var(--primary-color);
    
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap:1rem;
}

.btn-layout-1{
    margin-left: 10%;
    border: none;
    min-width: 10rem;
    color: white;
    letter-spacing: 1px;
    font-weight: bold;
    
    background-color: var(--primary-color);
    border-radius: 50px;

}
.createDatasetModal{
    width: 880px !important;
    height: 580px !important;; 

}
.datasetControlBtnSection{
    display: flex;
    /* background-color: var(--primary-color); */
}
.finishBtn{
    background-color: var(--primary-color) !important;
    font-family: codec !important;
    letter-spacing: 1px !important;
}
.category-div{
    margin-left: auto;
    text-align: center;
    justify-content: center;
    font-size: 14pt;
    color: var(--primary-color);
    
    letter-spacing: 1px;
}
.category-dropdown{
    width: 10rem;
}

.serchBarSection{
    width: 30% !important;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.createDatasetBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 20vh;
}

.card-1{
    width:350px;
    height: 290px;
}
.cardThumbnail-cointainer{

    text-align: center;
}
.cardThumbnail{
    max-width:350px;
    max-height: 197px;
    border-radius: 12px;
}




/* card-1 styling */

.card-1 {
  width: 350px;
  height: 290px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1), 0 8px 30px rgba(0, 0, 0, 0.15) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  transform: translateY(0);
}

.card-1:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 20px 40px rgba(0, 0, 0, 0.2);
}

.card-1::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  transition: opacity 0.3s ease;
  z-index: 0;
}

.card-1-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #390879; /* Primary color for text */
  font-family: var(--font-primary);
}

</style>