<template>
<div class="magicPicturePage">
  <div class="header header-layout-2">
    <div class="header-layout-2-logoSection">
         <img
            class="header-logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="header-logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
    <div class="header-layout-2-middleSection" >
       <div>
         Game code : {{$store.state.gameCode}} 
      </div>
    </div>
    <div class="header-layout-1-rightHeader" >
    <button v-if="this.questionData" class="btn-layout-2 createDatasetBtn leaveGameBtn" @click="leaveLobby">Leave</button>
    <div v-if="!this.questionData" class="header_nav_link" style="margin-left:20px" @click="$router.push('/homepage')">Home</div>
    </div>
  </div>
  <div class="actvity-layout-1">
    <div class="actvity-layout-1-question-section ">
      <div id="scribleQuestion" class="question-container" >
          <div>
          {{getWord}}
        </div>
      </div>

      <div class="drawingArea-div" >
        <canvas id="drawingArea" style="width: 100%;height: 100%;"/>
      </div>
      <div v-if="validateAdmin" class="actvity-layout-3-gameControlBtns">
        <div class="d-flex al-c"><div class="">Color:</div><div><input type="color" class="ml-2" id="favcolor" name="favcolor" v-model="color" @input="changeColor"></div></div>
        <div class="d-flex al-c"><div>Line Width:</div><div></div> <input class="ml-2" type="range" name="brushsize" min="0" max="20" id="brushSlider" step="1" v-model="lineWidth" @change="changeLineWidth"></div>
        <div><button class="btn-layout-2 color-2 game-btn-1" @click="changeToolType('pen')"><b-icon icon="pencil-fill" font-scale="1" aria-hidden="true"></b-icon></button></div>
        <div><button class="btn-layout-2 color-2 game-btn-1" @click="changeToolType('erase')"><b-icon icon="phone-fill" rotate="45" font-scale="1" aria-hidden="true"></b-icon></button></div>
        <div><button class="btn-layout-2 color-2 game-btn-1" @click="clearCanvas()"><b-icon icon="trash-fill" font-scale="1" aria-hidden="true"></b-icon></button></div>
        <div><button :disabled="currentQuestionNo>=$store.state.questionLength" :class="{ 'disableBtn': currentQuestionNo>=$store.state.questionLength }" class="btn-layout-2 color-2 game-btn-1" @click="getNextQuestion()"><b-icon icon="chevron-right" font-scale="1" aria-hidden="true"></b-icon></button></div>
      </div>








    </div>

    <div class="leaderBoard-section-layout-1 grid-lb-layout-2" v-if="this.questionData">
        <div>
          <div v-if="validateAdmin" class="">
            <input @input="searchPlayer($event)" type="text" ref="playerName" class="input-style-2 font-1_2"  placeholder="Search player">
          </div>
      </div>
      <div class="leaderBoard-div">
          <div class="leaderBoard-header">
            LeaderBoard
          </div>
           <div class="leaderBoardList">
          <ul class="rankingList">
            <li class="playerRanking" v-for="(val,index) in filteredPlayernamelist" :key="index">
              <div class="playerRank">{{index+1}}.</div>
              <div class="playerName">{{val.name}}</div>
              <div ><b-icon v-if="validateAdmin" @click="decreaseScore(val)" icon="dash-circle" font-scale="1" aria-hidden="true"></b-icon></div>
              <div class="playerScore">{{val.Score}}</div>
              <div ><b-icon v-if="validateAdmin" @click="increaseScore(val)" icon="plus-circle"  font-scale="1" aria-hidden="true"></b-icon></div>
            </li>
          </ul>  
        </div>
      </div>
      <!-- <button class="buzzerBtn" id="buzzerBtn" @click="buzzer" :disabled="disableBuzzerBtn"> 
      </button> -->
      <div>
        <div v-if="wrongAnswer" style="color:red">Wrong.Guess again</div>
      </div>
      
      <div class="">
        <input id="scribleWordAnswerBox" v-on:keyup.enter="checkAnswer" :disabled="firstBuzzerPressedBy.length>0" ref="scribleWord" type="text"  class="input-style-2 font-1_2"  placeholder="Guess the word and press enter">
      </div>

      <div>
        <div v-if="firstBuzzerPressedBy.length>0" class="buzzerpressedby">{{firstBuzzerPressedBy}}</div>
      </div>
        
       
    </div>


    <div class="scoreCard-div">
      <div v-if="!validateAdmin" class="scoreCard">
          <div>Your Score</div>
          <div class="t-color-2">{{getScore}}</div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
// import {
//   getUserList,
// } from "../api/index";
export default {

   data() {
    return {
      loginplayername:this.$store.state.playerName,
      isAdmin:false,
      playernamelist:[],
      filteredPlayernamelist:[],
      firstBuzzerPressedBy:"",
      currentQuestionNo:false,
      questionData:false,
      choices:[],
      showAnswer:false,
      showAnswerToAll:false,
      wrongAnswer:false,
      playerScore:0,




      canvas:false,
      ctx:false,
      pos :{ 
        x: 0,
        y: 0 
      },
      canvasPressed:false,
      remoteMousePressed:false,
      color:"var(--primary-color)",
      lineWidth:10,
      tooltype:"pen",
    };
  },
  created() {
  },
  watch: {
    '$store.state.playerName'(newVal) {
      this.loginplayername = newVal;
    }
  },
  computed: {
    getScore() {
      return this.playerScore;
    },
    validateAdmin(){
      return (this.isAdmin || this.$store.state.superAdmin);
    },
    disableBuzzerBtn(){
      return this.firstBuzzerPressedBy.length>0;
    },
    getWord(){
      let questionWord = this.questionData.toString();
      let word= questionWord.replace(/[a-zA-Z]/g, '_');
      return (this.isAdmin ||this.firstBuzzerPressedBy.length>0)?this.questionData:word;
    }

  },
  mounted() {
    // this.getUserData();
    

   this.$socket.on("questionNoScrible", (data) => {
    this.currentQuestionNo=data;
   });
   this.$socket.on("questionScrible", (questionData) => {
    
    this.questionData=questionData;
   });
   this.$socket.on("showAnswerScrible", (showAnswer) => {
    this.showAnswer=showAnswer;
   });
   this.$socket.on("showAnswerToAllScrible", (showAnswerToAll) => {
    this.showAnswerToAll=showAnswerToAll;
   });
  //  this.$socket.on("playernamelist", (data) => {
  //   let sortedData = data.sort(function (a, b) {return b.Score - a.Score;});
  //   this.playernamelist = this.filteredPlayernamelist = sortedData;
  //   if(!this.isAdmin)this.updateScore();
  //  });
   this.$socket.on("pressbuzzerByScrible", (data) => {
      console.log("buzzerpressedby  ",data)

    this.firstBuzzerPressedBy=data;
   });
   this.$socket.on("resetBuzzerclientScrible", (data) => {
    this.firstBuzzerPressedBy=data;
   });
   this.$socket.on("clearCanvas", () => {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
   });
   this.$socket.on("changeColor", (color) => {
    this.ctx.strokeStyle =color;
   });
   this.$socket.on("changeLineWidth", (width) => {
    this.ctx.lineWidth =width;
   });
   this.$socket.on("changeToolType", (type) => {
    this.tooltype =type;
   });





   //lobby

  this.$socket.on("roomData", (data) => {
    console.log("adsad sada",data);
    this.isAdmin = (data.adminId === localStorage.getItem("user_socket_id"))?true:false;
    let sortedData = data.users.sort(function (a, b) {return b.Score - a.Score;});
    this.playernamelist = this.filteredPlayernamelist = sortedData;
    if(!this.isAdmin)this.updateScore();
   });






   this.canvas = document.getElementById("drawingArea");

   this.ctx = this.canvas.getContext("2d");

    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;



    this.ctx.lineWidth = this.lineWidth; // width of line
    this.ctx.lineCap = "round"; // rounded end cap
    this.ctx.strokeStyle = this.color; // hex color of line





    this.canvas.addEventListener('mousedown',  (evt)=> {
      if(!this.isAdmin) return;
      this.canvasPressed = true;
      this.setPosition(evt);
      this.ctx.beginPath();
      this.ctx.moveTo(this.pos.x, this.pos.y);
      this.$socket.emit('draw-mousedown', { clientX: evt.clientX/this.canvas.width, clientY: evt.clientY/this.canvas.height});
    });
    
    this.canvas.addEventListener('mouseup',  (evt) =>{
      if(!this.isAdmin) return;
      this.canvasPressed = false;
      this.$socket.emit('draw-mouseup', {});
      console.log("mouseup:",evt)
    });
    
    this.canvas.addEventListener('mousemove',  (evt)=> {
      if(!this.isAdmin) return;
      if (this.canvasPressed) {
        this.setPosition(evt);
      console.log("type:",this.tooltype)

        if(this.tooltype=='pen') {
            this.ctx.globalCompositeOperation = 'source-over';
        } else {
            this.ctx.globalCompositeOperation = 'destination-out';
        }
        this.ctx.lineTo(this.pos.x, this.pos.y);
        this.ctx.moveTo(this.pos.x, this.pos.y);
        this.ctx.stroke();
        this.$socket.emit('draw-mousemove', { clientX: evt.clientX/this.canvas.width, clientY: evt.clientY/this.canvas.height});
      }
    });




        this.$socket.on('draw-mousedown',  (evt)=> {
            console.log('draw event', evt);
            this.canvasPressed = true;
            this.setReceiverPosition(evt);

            this.ctx.beginPath();
            this.ctx.moveTo(this.pos.x, this.pos.y);
        });
        this.$socket.on('draw-mouseup',  (evt) =>{
            console.log('draw event', evt);
            this.canvasPressed = false;
        });
        this.$socket.on('draw-mousemove',  (evt)=> {
            console.log('draw event', evt);
             if (this.canvasPressed) {
               this.setReceiverPosition(evt);
               if(this.tooltype=='pen') {
                    this.ctx.globalCompositeOperation = 'source-over';
                } else {
                    this.ctx.globalCompositeOperation = 'destination-out';
                }
                this.ctx.lineTo(this.pos.x, this.pos.y);
                this.ctx.moveTo(this.pos.x, this.pos.y);
                this.ctx.stroke();
            }           
        });


    // let word = document.getElementById("scribleWordAnswerBox");
    // console.log("word::element::",this.$refs.scribleWord,document.getElementById("input1"));
    // if(word){
    //   word.addEventListener("keypress", (event)=> {
    //     if (event.key === "Enter") {
    //       event.preventDefault();
    //       this.checkAnswer();
    //     }
    //   });
    // }





  },



  methods: {
    getNextQuestion() {
      this.$socket.emit("nextQuestionScrible", (this.currentQuestionNo + 1));
      this.resetBuzzer();
    },
    checkAnswer(){
      let answer =  this.$refs.scribleWord.value;
      this.$refs.scribleWord.value = "";
      if(this.questionData.toLowerCase() === answer.toLowerCase()){
      console.log("buzzerpressed",this.questionData,this.$refs.scribleWord.value)
        this.$socket.emit("pressbuzzerByScrible", this.loginplayername);
        this.showAnswerToAll=true;
      }else{
        this.wrongAnswer=true;
        setTimeout(() => {
        this.wrongAnswer = false;
        }, 2500);
      }
    },





    resetBuzzer(){
      this.$socket.emit("resetbuzzerScrible");
    },
    showAnswerToAllPlayers(){
      this.$socket.emit("showAnswerToAllScrible");
    },
    increaseScore(playerData){
      let pDetails=playerData;
      pDetails.Score +=100;
      this.$socket.emit("updatePlayerScore",pDetails);
    },
    decreaseScore(playerData){
      let pDetails=playerData;
      pDetails.Score -=100;
      this.$socket.emit("updatePlayerScore",pDetails);
    },
    searchPlayer(event){
    var input = event.target.value;
    let filterpPlayers = this.playernamelist.filter((player) => {
        return (
          player.name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
      this.filteredPlayernamelist = filterpPlayers;
    },
    updateScore() {
       var foundIndex = this.playernamelist.findIndex(
            (player) => player.id == localStorage.getItem("user_socket_id")
        );
     if(this.playernamelist){
          this.playerScore = this.playernamelist[foundIndex].Score;
        }
    },





    clearCanvas(){
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.$socket.emit("clearCanvas");
    },

    changeColor(){
      this.ctx.strokeStyle =this.color;
      this.$socket.emit("changeColor",this.color);
    },
    changeLineWidth(){
      this.ctx.lineWidth =this.lineWidth;
      this.$socket.emit("changeLineWidth",this.lineWidth);
    },
    changeToolType(toolType){
      this.tooltype = toolType;
      this.$socket.emit("changeToolType",toolType);
    },



    setPosition(e) {
      this.pos.x = e.clientX - this.canvas.offsetLeft;
      this.pos.y = e.clientY-this.canvas.offsetTop;
    },
    setReceiverPosition(e) {
      this.pos.x = (e.clientX*this.canvas.width) - this.canvas.offsetLeft;
      this.pos.y = (e.clientY*this.canvas.height)-this.canvas.offsetTop;
    },



  }
}
</script>
<style>
.drawingArea-div{
  background: white;
  height: 50vh;
  border: 1px solid var(--primary-color);
}
#scribleQuestion{
  letter-spacing: 7px;
}
.scribleTools{
  display: flex;
}
.actvity-layout-3-gameControlBtns{  
    display: grid;
    grid-auto-columns: 18% 37% 10% 10% 10% 10%;
    grid-auto-flow: column;
    gap: 1%;
    align-items: center;
    font-size: 1vw;
}

.al-c{
  align-items: center;
}
.grid-lb-layout-2{
    grid-template-rows: 15% 50% 10% 15% 10%;
}
</style>
