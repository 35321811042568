<template>
    <div>
      <!-- Trigger Button -->
      <b-button v-b-modal.confirmation-modal variant="danger">Open Confirmation Modal</b-button>
  
      <!-- Confirmation Modal -->
      <b-modal id="confirmation-modal" centered hide-footer title="Are you sure?">
        <div class="d-block text-center">
          <p>Are you sure you want to proceed?</p>
          <b-button variant="success" class="m-2" @click="confirm">Yes</b-button>
          <b-button variant="danger" class="m-2" @click="cancel">No</b-button>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      confirm() {
        // Handle the confirmation action
        this.$emit("finishActivity");
        this.$bvModal.hide('confirmation-modal');
        // Add your custom action here
        console.log('Confirmed');
      },
      cancel() {
        // Handle the cancellation action
        this.$bvModal.hide('confirmation-modal');
        // Add your custom action here
        console.log('Cancelled');
      }
    }
  }
  </script>
  

  <style scoped>
/* Center the modal content and style buttons */
.d-block.text-center {
  padding: 20px;
}

.b-button {
  margin: 0 10px;
}

.b-modal-content {
  background: linear-gradient(135deg, var(--primary-color) 0%, #6f42c1 100%);
  color: white;
}

.b-modal-header {
  background: var(--primary-color);
  color: white;
  border-bottom: none;
}

.b-modal-footer {
  border-top: none;
}
</style>
