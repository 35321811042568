<template>
  <b-modal
    id="add-question-modal"
    ref="add-question-modal"
    centered
    hide-footer
    title="Add Question"
    @hidden="closeModal"
    no-close-on-backdrop
  >
    <div class="question-type-container">
      <div class="question_card" @click="handleAddQuestion('mp')">
        <div class="question_container">
          <div style="font-size: 4rem;text-align: center;">
          <b-icon icon="image-fill" class="rounded-circle p-3 bg-primary" variant="light"></b-icon>
        </div>
          <b class="q_title">Magic Picture</b>
          <p>Picture quiz with buzzer</p>
        </div>
      </div>
      <div class="question_card" @click="handleAddQuestion('fff')">
        <div class="question_container">
          <div style="font-size: 4rem;text-align: center;">
          <b-icon icon="hdd-stack-fill" class="rounded-circle p-3 bg-primary" variant="light"></b-icon>
        </div>
          <b class="q_title">Quiz</b>
          <p>Quiz with multiple options</p>
        </div>
      </div>
      <div class="question_card" @click="handleAddQuestion('scbl')">
        <div class="question_container">
          <div style="font-size: 4rem;text-align: center;">
          <!-- <b-icon icon="brush-fill" class="rounded-circle p-3 bg-primary" variant="light"></b-icon> -->
          <b-iconstack class="rounded-circle p-3 bg-primary" style="color:#fff">
            <b-icon stacked icon="palette" shift-h="0" shift-v="3" scale="0.75" rotate="180"></b-icon>
            <b-icon stacked icon="brush-fill" shift-h="0" shift-v="-6" scale="0.75" rotate="45"></b-icon>
          </b-iconstack>
        </div>
          <b class="q_title">Scribble</b>
          <p>Draw and guess the word</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "add-question-ModalComponent",
  
  data() {
    return this.initialState();
  },
  emits: ["addNewQuestion"],
  
  methods: {
    initialState() {
      return {
        questionDetails: [
          // {
          //   icon: "image",
          //   title: "Magic Picture",
          //   subTitle: "",
          //   type: "mp",
          // },
          // {
          //   icon: "hdd-stack-fill",
          //   // icon:"menu-button-wide-fill",
          //   title: "Quiz",
          //   subTitle: "",
          //   type: "fff",
          // },
          // {
          //   icon: "brush-fill",
          //   // icon:"palette",
          //   title: "Scribble",
          //   subTitle: "",
          //   type: "scbl",
          // },
        ],
      };
    },
   handleAddQuestion(type){
    this.$emit("addNewQuestion",type);
    this.$bvModal.hide('add-question-modal');
   }
  },
};
</script>
<style >
#add-question-modal___BV_modal_body_{
  padding: 0px !important;
}
</style>

<style scoped>
.question-type-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background: linear-gradient(to right,var(--primary-color),#390879db, #390879ba ,#3908799e);
  padding: 2rem;
}

.question_card {
  text-align: center;
  background: white;
  border-radius: 2%;
  cursor: pointer;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.question_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.question_container {
  padding: 2px 16px;
}
.bg-primary{
  background: var(--primary-color) !important;
}
.q_title{
  font-family: 'Press Start 2P', cursive;
  font-size: 1.2rem;
}
</style>
