<template>
  <div class="game-ui">
    <div class="question-section">
      <label class="question-label">Question</label>
      <div class="textarea-container">
        <b-form-textarea
          id="question-textarea"
          maxlength="4000"
          rows="3"
          v-model="questionText"
          @input="update()"
          required
          :readonly="!enableInputs"
        ></b-form-textarea>
      </div>
    </div>

    <div class="image-uploader">
      <div class="image-box" @click="triggerFileInput">
        <input v-if="enableInputs" type="file" ref="fileInput" @change="handleFileChange" style="display: none;" />
        <div v-if="imageUrl" class="image-container">
          <img :src="imageUrl" alt="Selected Image" class="uploaded-image" />
          <span v-if="enableInputs" class="edit-icon" @click.stop="triggerFileInput">✎</span>
        </div>
        <div v-else class="placeholder_file">Click to upload an image</div>
      </div>
    </div>

    <div class="answer-section">
      <label class="answer-label">Answer</label>
      <div class="textarea-container">
        <b-form-textarea
          id="answer-textarea"
          maxlength="4000"
          rows="1"
          v-model="answer"
          @input="update()"
          required
          :readonly="!enableInputs"
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    importFlagProp:Boolean
  },
  data() {
    return {
      imageUrl:this.value.file || "",
      file: this.value.file || '',
      questionText: this.value.questionText || "",
      answer: this.value.answer || "",
      id: this.value.id || "",
      importFlag: this.importFlagProp || false
      };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.file = newValue.file ||"";
        this.questionText = newValue.questionText || "";
        this.answer = newValue.answer || '';
        this.id = this.value.id || "";
        this.imageUrl = newValue.question_url ||"";
        if (newValue.file) {
          this.loadFile(newValue.file);
        } 
         
      },
    },
  },
  computed:{
    enableInputs(){
      return !this.importFlagProp;
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.file.id = this.id;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
        this.update();
      }
    },
    loadFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    update() {
      this.$emit("input-change", {
        id: this.id,
        questionText: this.questionText,
        file: this.file,
        answer: this.answer,
        question_url:this.imageUrl
      });
    },
  }
};
</script>

<style scoped>
/* Main container */
.game-ui {
  font-family: 'Press Start 2P', cursive;
  color: #fff;
  background: linear-gradient(to right,var(--primary-color),#390879db, #390879ba ,#3908799e);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

/* Labels */
.question-label,
.answer-label {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: block;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Textarea containers */
.textarea-container {
  margin-bottom: 20px;
}

/* Textareas */
#question-textarea,
#answer-textarea {
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1em;
  resize: none;
  transition: transform 0.3s, box-shadow 0.3s;
}

#question-textarea:focus,
#answer-textarea:focus {
  outline: none;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Image uploader */
.image-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.image-box {
  width: 390px;
  height: 225px;
  border: 2px dashed rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  background: rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.image-box:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Placeholder text */
.placeholder_file {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Image container */
.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Uploaded image */
.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Edit icon */
.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s;
}

.edit-icon:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
</style>
