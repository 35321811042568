<template>
    <transition v-if="visible" name="overlay-slide">
    <div class="overlay">
      <div class="wrap">
        <div v-for="i in totalParticles" :key="i" :class="'particle particle-' + i"></div>
      </div>
      <div class="name-container">
        <span class="player-name">{{ playerName }}</span>
      </div>
    </div>
    </transition>
  </template>
  
  <script>
  
  export default {
    props: {
      playerName: {
        type: String,
        required: true,
        default: ""
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        totalParticles: 200,
      };
    },
    computed: {
      animationStyles() {
        let styles = '';
        for (let i = 1; i <= this.totalParticles; i++) {
          const delay = i * -0.015;
          styles += `
            @keyframes moveParticle-${i} {
              80% {
                opacity: 1;
              }
              100% {
                transform: translate3d(${Math.random() * 100}vw, ${Math.random() * 100}vh, 0);
                opacity: 0;
              }
            }
            .particle-${i} {
              animation: moveParticle-${i} 1.5s linear infinite;
              animation-delay: ${delay}s;
              position: absolute;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.8);
              transform: translate3d(50vw, 50vh, -1000px);
            }
          `;
        }
        return styles;
      }
    },
    watch: {
    visible(newValue) {
      if (this.playerName.length>0 && !newValue) {
        console.log("playerName-->",this.playerName,newValue)
        // When overlayVisible becomes false, trigger exit animation
        this.$el.classList.add('exit-overlay');
        setTimeout(() => {
          // Remove the overlay after animation completes
          this.$emit('destroy');
        }, 1500); // Adjust timing to match CSS animation duration
      }
        }
    },
    mounted() {
      // Create a style tag and inject animation styles dynamically
      const style = document.createElement('style');
      style.textContent = this.animationStyles;
      document.head.appendChild(style);
    }
  };
  </script>
  
  <style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }


.overlay-slide-enter-active,
.overlay-slide-leave-active {
  transition: transform 1s, opacity 1s;
}

.overlay-slide-enter,
.overlay-slide-leave-to {
  opacity: 1;
  transform: translate(0, 0);
}

.overlay-slide-enter-to,
.overlay-slide-leave {
  opacity: 0;
  transform: translate(50%, 50%);
}
  
  .wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
  }
  
  .name-container {
    position: relative;
    padding: 20px;
    background: #ffcc00;
    border: 5px solid #ff5733;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
    z-index: 1;
    text-align: center;
  }
  
  .player-name {
    font-size: 2rem;
    color: #000;
    text-shadow: 2px 2px 4px #fff;
  }
.exit-overlay {
  opacity: 0;
  transform: translate(50%, 50%);
}
  </style>
  