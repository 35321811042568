<template>
    <div>
      <div ref="calendly" class="calendly-inline-widget" :data-url="calendlyUrl" :style="widgetStyle"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CalendlyWidget',
    props: {
      calendlyUrl: {
        type: String,
        required: true
      },
      height: {
        type: String,
        default: '700px'
      },
      minWidth: {
        type: String,
        default: '320px'
      }
    },
    computed: {
      widgetStyle() {
        return {
          minWidth: this.minWidth,
          height: this.height
        };
      }
    },
    mounted() {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      this.$refs.calendly.appendChild(script);
    }
  };
  </script>
  
  <style scoped>
  .calendly-inline-widget {
    width: 100%;
  }
  </style>
  