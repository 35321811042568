<template>
    <div class="not-found">
      <div class="not-found-content">
        <h1 class="error-code">404</h1>
        <p class="error-message">Oops! The page you're looking for can't be found.</p>
        <router-link to="/" class="back-home-btn">Go Back Home</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotFound",
  };
  </script>
  
  <style scoped>
  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(145deg, #390879, #240551); /* Theme gradient */
    color: #fff;
    text-align: center;
    font-family: var(--font-primary);
  }
  
  .not-found-content {
    max-width: 600px;
  }
  
  .error-code {
    font-size: 8rem;
    font-weight: var(--font-weight-bold);
    margin: 0;
    background: linear-gradient(145deg, #f3e8ff, #dfccff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .error-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .back-home-btn {
    display: inline-block;
    padding: 12px 30px;
    font-size: 1rem;
    color: #390879;
    background-color: #fff;
    border-radius: 30px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
    font-weight: var(--font-weight-bold);
  }
  
  .back-home-btn:hover {
    background-color: #e0d7f5;
    transform: scale(1.05);
  }
  </style>
  