<template>
  <b-modal id="roomSettings-modal" ref="roomSettings-modal" scrollable  centered hide-footer title="Settings" @hidden="closeModal" no-close-on-backdrop>
    <b-form @submit="submit">
      <!-- Timer Option -->
      <b-row class="my-1">
        <b-col>
          <b-form-checkbox v-model="form.enableTimer">Enable Timer</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="my-1" v-if="form.enableTimer">
        <b-col>
          <label for="input-time">Select Time (seconds) :</label>
          <b-form-input id="input-time" v-model="form.timerDuration" type="number" min="1" required></b-form-input>
        </b-col>
      </b-row>

      <!-- Team Mode Option -->
      <b-row class="my-1">
        <b-col>
          <b-form-checkbox v-model="form.enableTeamMode">Enable Team Mode</b-form-checkbox>
        </b-col>
      </b-row>

      <!-- Number of Teams -->
      <b-row class="my-1" v-if="form.enableTeamMode">
        <b-col>
          <label for="input-teams">Select No. of Teams :</label>
          <b-form-input id="input-teams" v-model="form.teamCount" type="number" :min="2" required @input="updateTeams"></b-form-input>
        </b-col>
      </b-row>

      <!-- Team Names -->
      <template v-if="form.enableTeamMode" >
        <div class="modal-teams-name-input-container" >
          <b-row class="my-1"  v-for="(team, index) in form.teams" :key="team.id" >
            <b-col class="mb-2">
              <label :for="'team-name-' + team.id">Team {{ index + 1 }} Name:</label>
              <b-form-input
                :id="'team-name-' + team.id"
                v-model="team.name"
                :placeholder="'Enter name for Team ' + (index + 1)"
                required
              ></b-form-input>
            </b-col>
          </b-row>
        </div>
      </template>

      <!-- Submit Button -->
      <b-button pill class="mt-3 btn-layout-2 color-2" block size="lg" type="submit">Create</b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: 'RoomSettingsModalComponent',
  props: {
    isVisible: {
      type: Boolean
    }
  },
  data() {
    return this.initialState();
  },
  components:{},
  emits: ['update:isVisible', 'handleSubmit'],
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.$refs['roomSettings-modal'].show();
      }
    }
  },
  methods: {
    initialState() {
      return {
        form: {
          enableTimer: false,
          timerDuration: null,
          enableTeamMode: false,
          teamCount: 2,
          teams: [
            { id: 1, name: 'Team 1' },
            { id: 2, name: 'Team 2' }
          ]
        }
      };
    },
    resetData() {
      Object.assign(this.$data, this.initialState());
    },
    closeModal() {
      this.resetData();
      this.$emit('update:isVisible', false);
    },
    updateTeams() {
      const teamCount = parseInt(this.form.teamCount) || 2;
      this.form.teams = Array.from({ length: teamCount }, (v, i) => ({
        id: i + 1,
        name: this.form.teams[i]?.name || `Team ${i + 1}`
      }));
    },
    submit(event) {
      event.preventDefault();
      const config = {
        enableTimer: this.form.enableTimer,
        default_timer_time: this.form.enableTimer ? parseInt(this.form.timerDuration) : 0,
        enableTeamMode: this.form.enableTeamMode,
        teamCount: this.form.teamCount,
        teams: this.form.teams.map(team => ({
          id: team.id,
          name: team.name
        }))
      };
      this.$store.commit("setGameConfig", config);
      this.$emit('handleSubmit', config);
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.modal-teams-name-input-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
}
</style>