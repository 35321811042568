import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

export default new Vuex.Store({
    state: {
        playerName: "",
        gameCode: false,
        superAdmin: false,
        questionLength: 0,
        api_Url: process.env.VUE_APP_SERVER_ENDPOINT + "/",
        eventData: {},

        first_name: "",
        last_name: "",
        email: "",
        org_name: "",
        isLoggedIn: false,
        accessToken: "",

        skipAuth: false,
        userInfo: "",

        gameConfig:{
            enableTimer:false,
            default_timer_time:0,
            isTimerActive:false,
            enableTeamMode:false,
            teamCount:2,
            teams:[]
        },
        userGameConfig:{}
    },
    mutations: {
        setEventData(state, data) {
            state.eventData = data;
        },
        setGameConfig(state, data) {
            const gameConfig={
                enableTimer:data.enableTimer ||false,
                default_timer_time:data.default_timer_time ||0,
                isTimerActive:data.isTimerActive ||false,
                enableTeamMode:data.enableTeamMode ||false,
                teamCount:data.teamCount ||2,
                teams:data.teams ||[]
            };
            state.gameConfig = gameConfig;
        },
        setGameCode(state, data) {
            state.gameCode = data;
        },
        setSkipAuth(state, data) {
            state.skipAuth = data;
        },
        setQuestionLength(state, data) {
            state.questionLength = data;
        },
        setAccessToken(state, data) {
            state.accessToken = data;
        },
        setPlayerName(state, data) {
            console.log("setPlayerName", data)
            state.playerName = data;
        },
        setLoggedInUserInfo(state, data) {
            state.first_name = data.First_Name;
            state.last_name = data.Last_Name;
            state.email = data.Email;
            state.org_name = data.Org_Name;
            state.isLoggedIn = true;
            state.accessToken = data.Token;
            state.playerName = data.First_Name + " " + data.Last_Name;
            state.userInfo = data;
        },
        setUserGameConfig(state, data) {
            state.userGameConfig = data;
        },
    },
    actions: {},
    modules: {},
    plugins: [vuexLocal.plugin],
});