// Axios
import axios from "axios";
import store from "../store";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
const service = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
});
// const dataSet_service = axios.create({
//     baseURL: process.env.VUE_APP_DATASET_API_ENDPOINT,
// });

// request interceptor
service.interceptors.request.use(
    (config) => {
        if (store.state.skipAuth) {
            config.headers["skipAuth"] = true;
        }

        // do something before request is sent
        if (store.state.accessToken) {
            config.headers["Authorization"] = store.state.accessToken;
        }
        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    }
);


// response interceptor
service.interceptors.response.use(response => {
    return response;
}, async error => {

    // debugger
    if (error.response) {
        if (error.response.data === "Invalid Token") {
            console.log(error.response.data);
            window.location.href = "/";
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
});

export const CreateUser = (data) => {
    return service({
        url: "/register",
        method: "post",
        data,
    });
};
export const Login = (data) => {
    return service({
        url: "/login",
        method: "post",
        data,
    });
};
export const resetPassword = (data) => {
    return service({
        url: "/resetpassword",
        method: "post",
        data,
    });
};
export const sendResetPassEmail = (data) => {
    return service({
        url: "/sendresetpassemail",
        method: "post",
        data,
    });
};
export const demoRequest = (data) => {
    return service({
        url: "/demorequest",
        method: "post",
        data,
    });
};

export const getUserList = () => {
    return service({
        url: "/getuserdetails",
        method: "get",
    });
};
export const getEventDetails = () => {
    return service({
        url: "/geteventdetails",
        method: "get",
    });
};
export const verifyAccount = (params) => {
    return service({
        url: "/verify",
        method: "get",
        params
    });
};

export const feedbackDetails = (data) => {
    return service({
        url: "/feedbackdetails",
        method: "post",
        data,
    });
};
export const createMPEventDataset = (data) => {
    return service({
        url: "/creatempeventdataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const createDataset = (data) => {
    return service({
        baseURL: process.env.VUE_APP_DATASET_API_ENDPOINT,
        url: "/dataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const getDatasetbyId = (data_code) => {
    return service({
        baseURL: process.env.VUE_APP_DATASET_API_ENDPOINT,
        url: `/dataset/${data_code}`,
        method: "get",
        
    });
};
export const createFFFEventDataset = (data) => {
    return service({
        url: "/createfffeventdataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const createScblEventDataset = (data) => {
    return service({
        url: "/createscbleventdataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const getRoomData = (data_code) => {
    return service({
        url: `/join/${data_code}`,
        method: "get",
    });
};