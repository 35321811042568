<template>
<div class="homePage">
  <BlockUI v-if="loading" :message="loadingMsg">
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
  </BlockUI>
  <div class="header header-layout-1">
    <div class="header-layout-1-logoSection">
         <img
            class="header-logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="header-logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
    <div class="header-layout-1-rightHeader" >
      <div class="header_nav_link">
      Live Events
       <div>
        <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
        </div>
      </div>
      <div class="header_nav_link" style="margin-left:20px">
      Rewards
       <div>
        <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
        </div>
      </div>
      <div class="header_nav_link" style="margin-left:20px" @click="$router.push('/homepage')">Home</div>
    </div>
  </div>
  <div class="hero-container">

      <div class="hero-section-logo-div-2">
        <img
            class="hero-section-logo-2"
            src="../assets/scribble2.png"
        />
      </div>
      <div class="details-section">
        <div class="gameTitle">
          Scribble
        </div>
        <div class="gameAbout details-section-about">
          Lets see how quickly you can guess the word from the drawing.
        </div>
        <div class="slideCardCategory gameAbout details-section-about" >
              <div class="categoryWord">Category: </div>
              <div class="categoryType">Fun</div>
          </div>
        <div class="slideCardCategory gameAbout details-section-about" >
              <div class="categoryWord">Created By: </div>
              <div class="categoryType">TeamsMagic</div>
          </div>
        <!-- <div class="gameJoinBtn"> -->
            <button class="btn-layout-2 color-2"  @click="createLobby">Create Private Room</button>
            <div class="joingame-control-btns">
              <input class="input-style-2" type="text" ref="loobyCode" placeholder="Enter Code">
              <button class="btn-layout-2 bg-color-3"  @click="joinLobby">Join Room</button>
            </div>
        <!-- </div> -->
      </div>
  </div>
    <b-modal id="error-modal" ref="error-modal" size= 'sm'  buttonSize= 'sm'  headerClass='p-2 border-bottom-0'  footerClass='p-2 border-top-0' hide-header-close  ok-only centered title="Oops.">
    <p class="my-4">{{errMsg}}. Please try again</p>
  </b-modal>
</div>
</template>

<script>
export default {
  data(){
    return{
        data:this.$store.state.eventData,
        loadingMsg:"Please wait",
        loading:false,

        errMsg:'',
        }
  },
    created() {
    console.log("event",this.$socket)

  },
  mounted(){
    this.$socket.on("created", (data) => {
    if(data){
      this.$store.commit("setGameCode", data.gameCode);
      this.$store.commit("setQuestionLength", data.questionLength);
      // this.$router.push("/scriblegame").catch(()=>{});
      this.$router.push("/CustomActivity").catch(()=>{});
    }
   });
    this.$socket.on("connect_error", (err) => {
      console.log("connect_error",err.message); // prints the message associated with the error
        if(err.message){
          this.$refs['error-modal'].show();
          this.errMsg=err.message;
        }
        this.loading=false;
    });
    this.$socket.on("error", (err) => {
      console.log("Error",err.message); // prints the message associated with the error
    });
    this.$socket.on("joined", (data) => {
    if(data){
      this.$store.commit("setGameCode", data.gameCode);
      this.$store.commit("setQuestionLength", data.questionLength);
      this.$store.commit("setGameConfig", data.gameConfig);
      // this.$router.push("/scriblegame").catch(()=>{});
      this.$router.push("/CustomActivity").catch(()=>{});
    }
   });

  },
    methods:{
    createLobby(){
      this.loading=true;
      let username=this.$store.state.playerName;
      let evtCode=this.data.Event_Code;
      let dataCode=this.data.data_code;
      let userData =this.$store.state.userInfo;
       this.$socket.emit("create",{ username,evtCode,dataCode,userData}, error => {
        console.log("create err",error);
        this.loading=false;
       });
     },
     joinLobby(){
      this.loading=true;
      let username=this.$store.state.playerName;
      let room =this.$refs.loobyCode.value;
      let evtCode=this.data.Event_Code;
      let userData =this.$store.state.userInfo;
       this.$socket.emit("join",{ username, room , evtCode, userData}, error => {
        console.log("Join err",error);
        if(error){
          this.$refs['error-modal'].show();
          this.errMsg=error;
        }
        this.loading=false;
       });
     }
  }
}
</script>
<style>
.evtPageLogo{
  margin-top: 50px !important;
}
.evtPageInfo{
  margin-top: 50px !important;
}
</style>
