<template>
  <div class="game-ui">
    <div class="question-section">
      <label class="question-label">Question</label>
      <div class="textarea-container">
        <b-form-textarea
          id="question-textarea"
          maxlength="4000"
          rows="5"
          v-model="questionText"
          @input="updateOption()"
          required
          :readonly="!enableInputs"
        ></b-form-textarea>
      </div>
    </div>

    <label class="options-label">Options</label>
    <div v-for="(option, index) in options" :key="index" class="option-item">
      <b-input-group
        :prepend="option.optionLabel"
        class="option-group"
      >
        <b-form-input
          v-model="option.text"
          @input="updateOption()"
          :readonly="!enableInputs"
        ></b-form-input>
      </b-input-group>
    </div>

    <div class="radio-answer-row">
      <div class="options-label-col">
        <label class="correct-answer-label">Correct Answer:</label>
      </div>
      <div class="options-radio-col">
        <b-form-radio-group
          v-model="answer"
          :options="options"
          class="radio-group"
          value-field="optionLabel"
          text-field="optionLabel"
          @input="updateOption()"
          :disabled="!enableInputs"
        ></b-form-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    importFlagProp:Boolean
  },
  data() {
    return {
      options: this.value.options || [
        { text: "", optionLabel: "A" },
        { text: "", optionLabel: "B" },
        { text: "", optionLabel: "C" },
        { text: "", optionLabel: "D" },
      ],
      questionText: this.value.questionText || "",
      answer: this.value.answer || "",
      id: this.value.id || "",
      importFlag: this.importFlagProp || false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.options = newValue.options || [
          { text: "", optionLabel: "A" },
          { text: "", optionLabel: "B" },
          { text: "", optionLabel: "C" },
          { text: "", optionLabel: "D" },
        ];
        this.questionText = newValue.questionText || "";
        this.answer = newValue.answer || "";
        this.id = this.value.id || "";
      },
    },
  },
  computed:{
    enableInputs(){
      return !this.importFlagProp;
    }
  },
  methods: {
    updateOption() {
      this.$emit("input-change", {
        id: this.id,
        questionText: this.questionText,
        options: this.options,
        answer: this.answer,
      });
    },
  },
};
</script>

<style scoped>
/* Main container */
.game-ui {
  font-family: 'Press Start 2P', cursive;
  color: #fff;
  background: linear-gradient(to right,var(--primary-color),#390879db, #390879ba ,#3908799e);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

/* Labels */
.question-label,
.options-label,
.correct-answer-label {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: block;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Textarea containers */
.textarea-container {
  margin-bottom: 20px;
}

/* Textareas */
#question-textarea {
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1em;
  resize: none;
  transition: transform 0.3s, box-shadow 0.3s;
}

#question-textarea:focus {
  outline: none;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Options */
.option-item {
  margin-bottom: 10px;
}

.option-group {
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1em;
  transition: transform 0.3s, box-shadow 0.3s;
}

.option-group b-form-input {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
}

.option-group b-form-input:focus {
  outline: none;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Radio buttons */
.radio-answer-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.options-label-col {
  flex: 1;
}

.options-radio-col {
  flex: 2;
}

.radio-group {
  display: flex;
  justify-content: space-around;
}

.radio-group .custom-radio .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.6);
}

.radio-group .custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #2a5298;
  border-color: #1e3c72;
}
</style>
