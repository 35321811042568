<template>


  <div class="homePageBody">
    <div class="musicalSection defaultLM p-1">
        <div class="SectionTitle">Featured Activities</div>
        <div class="sectionContainer" >
          <div class="grid activity_list_Items" >
            <div class="item_card shadow border-radius-md border card-1 m-auto" 
            v-for="event in eventData"
            :key="event.title"
            @click="gotoEventPage(event)"
            :title="event.title"
            >
              <div class="cardThumbnail-cointainer">
                    <img
                        class="cardThumbnail"
                        style=""
                       :src="require( `../assets/${event.imgUrl}.png`)"
                        />
                </div>
              <div class="card-body p-2">
                <div class="videoTitle">{{event.title}}</div>
                <div class="videoBy">{{event.createBy}}</div>
                <div class="videoDetails">
                <div class="videoDuration">Category: {{event.category}}</div>
                <span class="dot"></span>
                <div class="videoUploadDate">{{event.uploadDate}}</div>
              </div>
            </div>
         
            </div>
          </div>
        </div>
    </div>
    <div class="musicalSection defaultLM p-1">
        <div class="SectionTitle">Upcoming Activities</div>
        <div class="sectionContainer" >
          <div class="grid activity_list_Items" >
            <div class="item_card shadow border-radius-md border card-1 m-auto"
            v-for="event in UpcomingEventData"
            :key="event.title"
            :title="event.title"
            >
            <div class="cardThumbnail-cointainer">
                    <img
                        class="cardThumbnail"
                        style="opacity:0.5"
                       :src="require( `../assets/${event.imgUrl}.png`)"
                        />
                </div>
              <div class="card-body p-2">
                <div class="videoTitle">{{event.title}}</div>
                <div class="videoBy">{{event.createBy}}</div>
            </div>
         
            </div>
          </div>
        </div>
    </div>
  </div>

</template>

<script>
export default {
  data(){
    return{
        eventData:[
          {
            title:"The Magic Picture",
            createBy:"TeamsMagic",
            category:"Fun",
            uploadDate:"7 days ago",
            details:"Lets see how strong your IQ is from Solving the Mystery.",
            eventUrl:"/magicpicture",
            data_code:"teamsmagic_mp_001",
            Event_Code:"mp",
            imgUrl:'magicPicture',
            preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/magicPicture.png",
            name:"The Magic Picture",
            Created_By:"TeamsMagic",
            Event_Type:"Magic Picture",
          },
          {
            title:"Fastest Finger First",
            createBy:"TeamsMagic",
            category:"Fun",
            uploadDate:"5 days ago",
            details:"Lets see how strong your GK is from Answering simple Quiz.",
            eventUrl:"/fastestfingerfirst",
            data_code:"teamsmagic_fff_001",
            Event_Code:"fff",
            imgUrl:'quiz_game',
            preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/quiz_game.png",
            name:"Fastest Finger First",
            Event_Type:"Fastest Finger First",
            Created_By:"TeamsMagic"
          },
          {
            title:"Scribble",
            createBy:"TeamsMagic",
            category:"Fun",
            uploadDate:"1 day ago",
            details:"Lets see how quickly you can guess the word from the drawing.",
            eventUrl:"/scriblegame",
            data_code:"teamsmagic_scbl_001",
            Event_Code:"scbl",
            imgUrl:'scribble2',
            preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/scribble2.png",
            name:"Scribble",
            Event_Type:"Scribble",
            Created_By:"TeamsMagic"
          },
        ],
        UpcomingEventData:[
          {
            title:"Treasure Hunt",
            createBy:"TeamsMagic",
            category:"Fun",
            uploadDate:"7 days ago",
            details:"Lets see how strong your IQ is from Solving the Mystery.",
            eventUrl:"/magicpicture",
            evtCode:"mp",
            imgUrl:'treasureHuntImg',
          },
          {
            title:"Escape Room",
            createBy:"TeamsMagic",
            category:"Fun",
            uploadDate:"1 day ago",
            details:"Lets see how quickly you can guess the word from the drawing.",
            eventUrl:"/scriblegame",
            evtCode:"scbl",
            imgUrl:'escapeRoomImg',
          },
        ]
        }
  },
  computed:{
   
  },
  mounted(){

  },
    methods:{
      gotoEventPage(event){
        this.$store.commit("setEventData", event);
        let path=this.getEventInfoPath(event.Event_Code);
        this.$router.push(path);
      }
  }
}
</script>


<style>
.meditationPageBody{
  margin-top: 10vh;
  height: 90vh;
  position: absolute;
  width: 100%;
}
.defaultLM{
    margin-left: 77px;
    margin-right: 77px;
}
.cardVideo{
  border-radius: 15px !important;
}
.activity_list_Items{
  display: flex;
  flex-wrap: wrap;
}

.videoTitle{
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.videoBy{
  font-size: 1rem;
    font-weight: 400;
}
.videoDetails{
  display: flex;
  font-size: .75rem;
  font-weight: 400;
}
.dot {
  height: 0.75rem;
  width: 0.75rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}
.videoWrapper{
  overflow: hidden;
}
.grid {
  display: grid;
  grid-template-columns:  repeat(auto-fit, minmax(350px, 1fr));
  margin-bottom: 8px;
  grid-auto-flow: dense;
  row-gap: 30px;
  column-gap: 8px;
  justify-content: center !important;
 
}
.item_card:hover {
  transform: translate3D(0,-1px,0) scale(1.05) !important;
}
</style>