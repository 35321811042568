<template>
  <div class="magicPicturePage">
    <div class="header header-layout-2">
      <div class="header-layout-2-logoSection">
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img class="header-logoImg" src="../assets/loginPageLogo.png" />
      </div>
      <div class="header-layout-2-middleSection">
        <div class="game-link-container">
          <span class="game-link">Join Link : </span>
          <b-button @click="copyLink" class="copy-button">
            {{ getCopyLinkText }}
          </b-button>
        </div>
      </div>
      <div class="header-layout-1-rightHeader">
        <button
          v-if="questionData"
          class="btn-layout-2 createDatasetBtn leaveGameBtn"
          @click="leaveLobby"
        >
          Leave
        </button>
        <div
          v-if="!questionData"
          class="header_nav_link"
          style="margin-left: 20px"
          @click="$router.push('/homepage')"
        >
          Home
        </div>
      </div>
    </div>
    <div class="actvity-layout-1">
          <div v-if="currentQuestionType==activityConstants.magicPicure_code" class="actvity-layout-1-question-section">
            <div class="question-container">
              <div>
                {{ questionData }}
              </div>
            </div>
            <div class="question-middle-container">
              <img v-if="pictureUrl" class="pictureImg" :src="pictureUrl" />
            </div>
            <div v-if="validateAdmin" class="actvity-layout-1-gameControlBtns">
              <button
                :disabled="currentQuestionNo <= 1"
                class="btn-layout-2 color-2 game-btn-1"
                :class="{ disableBtn: currentQuestionNo <= 1 }"
                @click="getPreviousQuestion()"
              >
                <b-icon
                  icon="chevron-left"
                  font-scale="1"
                  aria-hidden="true"
                ></b-icon>
              </button>
              <button
                class="btn-layout-2 color-2 game-btn-2"
                @click="resetBuzzer()"
              >
                Reset Buzzer
              </button>
              <button class="btn-layout-2 color-2 game-btn-3" @click="getPicture()">
                Play
              </button>
              <button
                class="btn-layout-2 color-2 game-btn-4"
                @click="showAnswerToAllPlayers()"
              >
                Reveal Answer
              </button>
              <button
                :disabled="currentQuestionNo >= $store.state.questionLength"
                :class="{
                  disableBtn: currentQuestionNo >= $store.state.questionLength,
                }"
                class="btn-layout-2 color-2 game-btn-1 gird-end"
                @click="getNextQuestion()"
              >
                <b-icon
                  icon="chevron-right"
                  font-scale="1"
                  aria-hidden="true"
                ></b-icon>
              </button>
            </div>
            <div
              class="actvity-layout-1-answerSection"
              v-if="
                (showAnswer && validateAdmin) || (showAnswer && showAnswerToAll)
              "
            >
              Answer : {{ showAnswer }}
            </div>
          </div>

        <div v-if="currentQuestionType==activityConstants.fff_code" class="actvity-layout-1-question-section">
          <div class="question-container"> 
            <div>
              {{questionData}}
            </div>
          </div>
            <div class="optionsSection">
              <button @click="buzzerfff('A')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">A.{{choices[0].text}}</div></button>
              <button @click="buzzerfff('B')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">B.{{choices[1].text}}</div></button>
              <button @click="buzzerfff('C')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">C.{{choices[2].text}}</div></button>
              <button @click="buzzerfff('D')" :disabled="disableBuzzerBtn" class="optionItem "><div v-if="choices.length>0">D.{{choices[3].text}}</div></button>
            </div>

          <div  class="">
            <div class="gameControlSection-1" v-if="validateAdmin">
              <div><button :disabled="currentQuestionNo<=1" class="btn-layout-2 color-2 game-btn-1" :class="{ 'disableBtn': currentQuestionNo<=1 }" @click="getPreviousQuestion()"><b-icon icon="chevron-left" font-scale="1" aria-hidden="true"></b-icon></button></div>
              <div><button class="btn-layout-2 color-2" @click="getFFFOptions()">Play</button></div>
              <div><button :disabled="currentQuestionNo>=$store.state.questionLength" :class="{ 'disableBtn': currentQuestionNo>=$store.state.questionLength }" class="btn-layout-2 color-2 game-btn-1" @click="getNextQuestion()"><b-icon icon="chevron-right" font-scale="1" aria-hidden="true"></b-icon></button></div>
            </div>
            
          </div>
          <div class="actvity-layout-1-answerSection" v-if="showAnswer && validateAdmin || showAnswer && showAnswerToAll  || firstBuzzerPressedBy.length>0">
            Answer : {{showAnswer}}
          </div> 
        </div>

        <div v-if="currentQuestionType==activityConstants.scribble_code" class="actvity-layout-1-question-section ">
          <div id="scribleQuestion" class="question-container" >
              <div>
              {{getWord}}
            </div>
          </div>

          <div class="drawingArea-div" >
            <canvas id="drawingArea" style="width: 100%;height: 100%;"/>
          </div>
          <div v-if="validateAdmin" class="actvity-layout-3-gameControlBtns">
            <div class="d-flex al-c"><div class="">Color:</div><div><input type="color" class="ml-2" id="favcolor" name="favcolor" v-model="color" @input="changeColor"></div></div>
            <div class="d-flex al-c"><div>Line Width:</div><div></div> <input class="ml-2" type="range" name="brushsize" min="0" max="20" id="brushSlider" step="1" v-model="lineWidth" @change="changeLineWidth"></div>
            <div><button class="btn-layout-2 color-2 game-btn-1" @click="changeToolType('pen')"><b-icon icon="pencil-fill" font-scale="1" aria-hidden="true"></b-icon></button></div>
            <div><button class="btn-layout-2 color-2 game-btn-1" @click="changeToolType('erase')"><b-icon icon="phone-fill" rotate="45" font-scale="1" aria-hidden="true"></b-icon></button></div>
            <div><button class="btn-layout-2 color-2 game-btn-1" @click="clearCanvas()"><b-icon icon="trash-fill" font-scale="1" aria-hidden="true"></b-icon></button></div>
            <div><button :disabled="currentQuestionNo>=$store.state.questionLength" :class="{ 'disableBtn': currentQuestionNo>=$store.state.questionLength }" class="btn-layout-2 color-2 game-btn-1" @click="getNextQuestion()"><b-icon icon="chevron-right" font-scale="1" aria-hidden="true"></b-icon></button></div>
          </div>

        </div> 

      <div class="leaderBoard-section-layout-1" v-if="questionData" :class="(currentQuestionType==activityConstants.scribble_code)?'grid-lb-layout-2':''">
        <div>
          <div v-if="validateAdmin" class="">
            <input
              @input="searchPlayer($event)"
              type="text"
              ref="playerName"
              class="input-style-2 font-1_2"
              placeholder="Search player"
            />
          </div>
        </div>

        <div class="leaderBoard-div">
          <div class="leaderBoard-header">LeaderBoard</div>
          <div class="leaderBoardList">
            <ul class="rankingList">
              <li
                class="playerRanking"
                v-for="(val, index) in filteredPlayernamelist"
                :key="index"
              >
                <div class="playerRank">{{ index + 1 }}.</div>
                <div class="playerName">{{ val.name }}</div>
                <div>
                  <b-icon
                    v-if="validateAdmin"
                    @click="decreaseScore(val)"
                    icon="dash-circle"
                    font-scale="1"
                    aria-hidden="true"
                  ></b-icon>
                </div>
                <div class="playerScore">{{ val.Score }}</div>
                <div>
                  <b-icon
                    v-if="validateAdmin"
                    @click="increaseScore(val)"
                    icon="plus-circle"
                    font-scale="1"
                    aria-hidden="true"
                  ></b-icon>
                </div>
              </li>
            </ul>
          </div>
        </div>


        <div  v-if="currentQuestionType==activityConstants.magicPicure_code" class="buzzer-div">
          <div v-if="firstBuzzerPressedBy.length > 0" class="buzzerpressedby" :title="firstBuzzerPressedBy">
            {{ firstBuzzerPressedBy }}
          </div>
          <button
            v-else
            class="pushable"
            id="buzzerBtn"
            @click="buzzer"
            :disabled="disableBuzzerBtn"
          >
            <span class="buzzerShadow"></span>
            <span class="buzzerEdge"></span>
            <span class="buzzerFront">
              <span class="buzzerText">Buzzer</span>
            </span>
          </button>
        </div>

        <div v-if="currentQuestionType==activityConstants.fff_code" class="buzzer-div">
          <div v-if="firstBuzzerPressedBy.length>0" class="buzzerpressedby" :title="firstBuzzerPressedBy">{{firstBuzzerPressedBy}}</div>
        </div>


        <template v-if="currentQuestionType==activityConstants.scribble_code">
          <div>
            <div v-if="wrongAnswer" style="color:red">Wrong.Guess again</div>
          </div>
          
          <div class="">
            <input id="scribleWordAnswerBox" v-on:keyup.enter="checkAnswer" :disabled="firstBuzzerPressedBy.length>0" ref="scribleWord" type="text"  class="input-style-2 font-1_2"  placeholder="Guess the word and press enter">
          </div>

          <div>
            <div v-if="firstBuzzerPressedBy.length>0" class="buzzerpressedby" :title="firstBuzzerPressedBy">{{firstBuzzerPressedBy}}</div>
          </div>
        
        </template>

      </div>

      <div class="scoreCard-div">
        <div v-if="enableTeamMode" class="team-name">({{ userGameConfig.name }})</div>
        <div v-if="!validateAdmin" class="scoreCard">
          
          <div>Your Score</div>
          <div class="t-color-2">{{ getScore }}</div>
        </div>
        <div v-if="enableTimer" class="countdown">
          <div id="timer">{{ timer }}</div>
          <div class="progress-bar">
            <div class="progress" :style="{ width: progressWidth + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
    <BuzzerOverlay v-if="firstBuzzerPressedBy.length>0" :playerName="firstBuzzerPressedBy" :visible="overlayVisible" />
  </div>
</template>

<script>
import {activityConstants} from "../constants/activityConstants"
import BuzzerOverlay from '../components/BuzzerOverlay.vue';
export default {
  components: {
    BuzzerOverlay
  },
  data() {
    return {
      activityConstants,
      loginplayername: this.$store.state.playerName,
      isAdmin: false,
      playernamelist: [],
      filteredPlayernamelist: [],
      firstBuzzerPressedBy: "",
      currentQuestionNo: false,
      currentQuestionType: false,
      questionData: false,
      pictureUrl: false,
      showAnswer: false,
      showAnswerToAll: false,
      playerScore: 0,

      //fff
      choices:[],
      wrongAnswer:false,

      //scbl
      canvas:false,
      ctx:false,
      pos :{ 
        x: 0,
        y: 0 
      },
      canvasPressed:false,
      remoteMousePressed:false,
      color:"#390879",
      lineWidth:10,
      tooltype:"pen",

      //timer
      enableTimer:this.$store.state.gameConfig?.enableTimer ||false,
      totalTimerTime:this.$store.state.gameConfig?.default_timer_time,
      timer:this.$store.state.gameConfig?.default_timer_time, // default timer value
      interval:null,
      isTimerActive:false,

      //overlay
      overlayVisible: false,

      //optimizations
      receivedChunks: 0, // Track number of chunks received
      totalUsersExpected: 0, // Track expected total number of users

      //teamMode
      enableTeamMode:this.$store.state.gameConfig?.enableTeamMode, 
      teamCount:this.$store.state.gameConfig?.teamCount, 
      teams:this.$store.state.gameConfig?.teams, 
      userGameConfig:this.$store.state.userGameConfig || {id:0,name:"",Score:0}, 

      //copy link
      copyLinkText:"Copy Link",

      //userID
      currentUserId:this.$loggeduserId ||0,
      
    };
  },
  created() {},
  computed: {
    getCopyLinkText(){
      return this.copyLinkText;
    },
    gameLink() {
      const domain = window.location.origin; // Get the current domain
      const gameCode = this.$store.state.gameCode; // Access the game code from the Vuex store
      return `${domain}/join/${gameCode}`; // Construct the dynamic game link
    },
    progressWidth() {
      return (this.timer / this.totalTimerTime) * 100;
    },

    getImgUrl() {
      return this.$store.state.api_Url + this.pictureUrl;
    },

    validateAdmin() {
      return this.isAdmin || this.$store.state.superAdmin;
    },
    getScore() {
      return this.playerScore;
    },
    disableBuzzerBtn() {
       if(this.currentQuestionType==this.activityConstants.fff_code){
        return this.firstBuzzerPressedBy.length>0 || this.wrongAnswer || this.choices.length<=0;
      }
      else{
        return this.firstBuzzerPressedBy.length > 0;
      }
    },

    getWord(){
      let questionWord = this.questionData?.toString()||"";
      let word= questionWord.replace(/[a-zA-Z]/g, '_');
      return (this.isAdmin ||this.firstBuzzerPressedBy.length>0)?this.questionData:word;
    }
  },
  watch: {
    "$store.state.playerName"(newVal) {
      this.loginplayername = newVal;
    },

    "currentQuestionType"(newValue) {
      if (newValue && newValue===activityConstants.scribble_code) {
        this.$nextTick(() => {
          this.setupCanvas();
        });
      }
    }
  },
  beforeDestroy() {
    this.enableTimer=false;
    this.clearTimerInterval();
    this.$store.commit("setGameConfig", {});
    this.$store.commit("setUserGameConfig",{});

    const socketEvents = [
      "question",
      "playPicture",
      "showAnswer",
      "showAnswerToAll",
      "pressbuzzerBy",
      "resetBuzzerclient",
      "resumeTimer",
      "playFFF",
      "showAnswerFFF",
      "showAnswerToAllFFF",
      "pressbuzzerByFFF",
      "resetBuzzerclientFFF",
      "questionScrible",
      "showAnswerScrible",
      "showAnswerToAllScrible",
      "pressbuzzerByScrible",
      "resetBuzzerclientScrible",
      "clearCanvas",
      "changeColor",
      "changeLineWidth",
      "changeToolType",
      "questionDetails",
      "roomData",
      "gameConfig",
      "draw-mousedown",
      "draw-mouseup",
      "draw-mousemove",
      "userLeft"
    ];

    for(let i=0;i<socketEvents.length;i++){
      this.$socket.off(socketEvents[i]);
    }

  },
  mounted() {
   
    //mp
    this.$socket.on("question", (questionData) => {
      this.questionData = questionData;
    });
    this.$socket.on("playPicture", (pictureUrl) => {
      this.pictureUrl = pictureUrl;
      //timer
      if(this.enableTimer && !this.interval && pictureUrl && !this.firstBuzzerPressedBy ){
      this.startLocalCountdown();
      }
    });
    this.$socket.on("showAnswer", (showAnswer) => {
      this.showAnswer = showAnswer;
    });
    this.$socket.on("showAnswerToAll", (showAnswerToAll) => {
      this.showAnswerToAll = showAnswerToAll;
    });
    this.$socket.on("pressbuzzerBy", (data) => {
      this.showOverlay();
      this.firstBuzzerPressedBy = data;
      if(this.enableTimer && data){
        this.clearTimerInterval();
      }
    });
    this.$socket.on("resetBuzzerclient", (data) => {
      this.firstBuzzerPressedBy = data?.firstBuzzerPressedBy ||"";
    });
    this.$socket.on("resumeTimer", (data) => {
      console.log("timer",data);
      if(this.enableTimer){
        this.startLocalCountdown();
      }
    });


    //fff

   this.$socket.on("playFFF", (choices) => {
    this.choices=choices;

    //timer
    if(this.enableTimer && !this.interval && choices.length>0 && !this.firstBuzzerPressedBy ){
      this.startLocalCountdown();
      }
   });
   this.$socket.on("showAnswerFFF", (showAnswer) => {
    this.showAnswer=showAnswer;
   });
   this.$socket.on("showAnswerToAllFFF", (showAnswerToAll) => {
    this.showAnswerToAll=showAnswerToAll;
   });
   this.$socket.on("pressbuzzerByFFF", (data) => {
    this.showOverlay();
    this.firstBuzzerPressedBy=data;
    //timer
    if(this.enableTimer && data){
        this.clearTimerInterval();
      }
   });
   this.$socket.on("resetBuzzerclientFFF", (data) => {
    this.firstBuzzerPressedBy=data;
    this.wrongAnswer=false;
   });


   //scbl
   this.$socket.on("questionScrible", (questionData) => {
    
    this.questionData=questionData;
   });

   this.$socket.on("showAnswerScrible", (showAnswer) => {
    this.showAnswer=showAnswer;
   });
   this.$socket.on("showAnswerToAllScrible", (showAnswerToAll) => {
    this.showAnswerToAll=showAnswerToAll;
   });

   this.$socket.on("pressbuzzerByScrible", (data) => {
    this.showOverlay();
    this.firstBuzzerPressedBy=data;
    if(this.enableTimer && data){
        this.clearTimerInterval();
      }
   });
   this.$socket.on("resetBuzzerclientScrible", (data) => {
    this.firstBuzzerPressedBy=data;
   });
   this.$socket.on("clearCanvas", () => {
     this.canvas = document.getElementById("drawingArea");
     if(this.canvas){
      if(this.ctx ==false){
        this.ctx = this.canvas.getContext("2d");
      }
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
     }
   });
   this.$socket.on("changeColor", (color) => {
    this.ctx.strokeStyle =color;
   });
   this.$socket.on("changeLineWidth", (width) => {
    this.ctx.lineWidth =width;
   });
   this.$socket.on("changeToolType", (type) => {
    this.tooltype =type;
   });



    this.setupCanvas();


      //general
    this.$socket.on("questionDetails", (data) => {
      this.currentQuestionNo = data?.q_no || 1;
      this.currentQuestionType = data?.q_type || "";

      //reset
      this.firstBuzzerPressedBy ='';
      this.wrongAnswer=false;
      //timer
      if(this.enableTimer){
        this.clearTimerInterval();
        this.timer = this.totalTimerTime;
      }
    });
    
    this.$socket.on("roomData", this.handleRoomData);
    this.$socket.on("userLeft", this.handleUserLeft);
    this.$socket.on("gameConfig", (data) => {
      if(data){
        this.enableTimer=data.enableTimer || false;
        this.timer=parseInt(data.playTimer_left) || this.totalTimerTime;
        this.isTimerActive=data.isTimerActive || false;

        if(data.enableTimer && data.isTimerActive){
          this.startLocalCountdown();
        }
      }
    })


  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.gameLink).then(
        () => {
          this.copyLinkText = "Copied"
          setTimeout(() => {
            this.copyLinkText = "Copy Link"
          }, 2000);
        }
      );
    },
    handleRoomData(data) {
    // Update admin status
    this.isAdmin = data.adminId == this.currentUserId;

    // Track total number of expected users (used when receiving complete data)
    if (data.totalUsers) {
      this.totalUsersExpected = data.totalUsers;
    }

    // Determine type of update
    if (data.users && data.users.length > 0) {
      // Handle user data updates
      this.updatePlayerList(data.users);
    }

    // Handle completion of all expected chunks
    if (this.receivedChunks >= this.totalUsersExpected && this.totalUsersExpected > 0) {
      this.finalizePlayerList(data);
    }
  },
  
  updatePlayerList(users) {
    // Loop through each user and update or add them to the player list
    users.forEach(newUser => {
      const existingUserIndex = this.playernamelist.findIndex(user => user.id === newUser.id);
      
      if (existingUserIndex !== -1) {
        // Update existing user data
        this.playernamelist[existingUserIndex] = { ...this.playernamelist[existingUserIndex], ...newUser };
      } else {
        // Add new user if not already in the list
        this.playernamelist.push(newUser);
      }
    });

    // Increment the received chunk count
    this.receivedChunks += users.length;
  },

  finalizePlayerList(data) {
    // Sort the final list by score
    if(this.enableTeamMode){
      this.teams = data?.teams.sort((a, b) => b.Score - a.Score);
      this.filteredPlayernamelist = [...this.teams];
    }else{
      this.playernamelist.sort((a, b) => b.Score - a.Score);
      this.filteredPlayernamelist = [...this.playernamelist];
    }


    // Trigger any necessary updates for non-admins
    if (!this.isAdmin) {
      this.updateScore();
    }

    // Reset chunk tracking variables for future updates
    this.receivedChunks = 0;
    this.totalUsersExpected = 0;
  },
  handleUserLeft(userId) {
    // Remove user from the player list when they leave
    this.playernamelist = this.playernamelist.filter(user => user.id != userId);
    this.filteredPlayernamelist = [...this.playernamelist];
  },
    showOverlay() {
      this.overlayVisible = true;
      setTimeout(() => {
        this.overlayVisible = false;
      }, 2500); // Hide after 2 seconds
    },
    clearTimerInterval(){
      clearInterval(this.interval);
      this.interval = null;
    },
    startLocalCountdown() {
      if(this.enableTimer){
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer -= 1;
        }
        else if(this.timer == 0 && this.interval !=null){  
          if(this.validateAdmin){
            if (this.currentQuestionType === "mp") {
            this.$socket.emit("pressbuzzer", "Time's Up!");
            this.$socket.emit("showAnswerToAll");
            } else if (this.currentQuestionType === "fff") {
              this.$socket.emit("pressbuzzerFFF", "Time's Up!");
            } else if (this.currentQuestionType === "scbl") {
              this.$socket.emit("pressbuzzerByScrible", "Time's Up!");
            }
          }
          this.clearTimerInterval();
        }
      }, 1000);
     }
    },
    getNextQuestion() {
      this.$socket.emit("changeQuestion_custom", this.currentQuestionNo + 1);
      // this.resetBuzzer();
    },
    getPreviousQuestion() {
      this.$socket.emit("changeQuestion_custom", this.currentQuestionNo - 1);
      // this.resetBuzzer();
    },
    getPicture() {
      this.$socket.emit("playPicture", this.currentQuestionNo);
    },
    buzzer() {
      const name = (this.enableTeamMode)?this.loginplayername +'('+this.userGameConfig.name+')': this.loginplayername
      this.$socket.emit("pressbuzzer", name);
    },
    resetBuzzer() {
      if(this.firstBuzzerPressedBy){
        const gameConfigData = {
          enableTimer:this.enableTimer,
          default_timer_time:this.timer
        }
        //mp
        if(this.currentQuestionType===this.activityConstants.magicPicure_code){
          this.$socket.emit("resetbuzzer",gameConfigData);
        }      
        if(this.currentQuestionType===this.activityConstants.fff_code){
          this.$socket.emit("resetbuzzerFFF",gameConfigData);
          this.wrongAnswer=false;
        }      
        if(this.currentQuestionType===this.activityConstants.scribble_code){
          this.$socket.emit("resetbuzzerScrible",gameConfigData);
        }   
      }   
    },


    //fff
    getFFFOptions() {
      this.$socket.emit("playFFF", this.currentQuestionNo);
    },
    buzzerfff(answer){
      if(this.showAnswer === answer){
        const name = (this.enableTeamMode)?this.loginplayername +'('+this.userGameConfig.name+')': this.loginplayername
        this.$socket.emit("pressbuzzerFFF", name);
        this.showAnswerToAll=true;
      }else{
        this.wrongAnswer=true;
        this.showAnswerToAll=true;
      }
    },




    showAnswerToAllPlayers() {
      if(this.currentQuestionType==this.activityConstants.scribble_code){
        this.$socket.emit("showAnswerToAllScrible");
      }else{
        this.$socket.emit("showAnswerToAll");
      }
    },
    increaseScore(playerData) {
      let pDetails = playerData;
      pDetails.Score += 100;
      const emitName = (this.enableTeamMode)?"updateTeamScore":"updatePlayerScore";
      this.$socket.emit(emitName, pDetails);
    
    },
    decreaseScore(playerData) {
      let pDetails = playerData;
      pDetails.Score -= 100;
      const emitName = (this.enableTeamMode)?"updateTeamScore":"updatePlayerScore";
      this.$socket.emit(emitName, pDetails);
    },
    searchPlayer(event) {
      var input = event.target.value;
      const list = (this.enableTeamMode)?this.teams:this.playernamelist;
      let filterpPlayers = list.filter((player) => {
        return player.name.toLowerCase().startsWith(input.toLowerCase());
      });
      this.filteredPlayernamelist = filterpPlayers;
    },
    updateScore() {
      let foundIndex=-1;
      if(this.enableTeamMode){
          foundIndex = this.teams.findIndex(
          (team) => team.id == this.userGameConfig.id
           );
        if (foundIndex>-1) {
          this.playerScore = this.teams[foundIndex].Score;
        }
      }else{
        foundIndex = this.playernamelist.findIndex(
          (player) => player.id == this.currentUserId
        );
        if (foundIndex>-1) {
          this.playerScore = this.playernamelist[foundIndex].Score;
        }
      }
    },



    //scbl

    checkAnswer(){
      let answer =  this.$refs.scribleWord.value;
      this.$refs.scribleWord.value = "";
      if(this.questionData.toLowerCase() === answer.toLowerCase()){
        const name = (this.enableTeamMode)?this.loginplayername +'('+this.userGameConfig.name+')': this.loginplayername
        this.$socket.emit("pressbuzzerByScrible", name);
        this.showAnswerToAll=true;
      }else{
        this.wrongAnswer=true;
        setTimeout(() => {
        this.wrongAnswer = false;
        }, 2500);
      }
    },

    
    clearCanvas(){
      this.canvas = document.getElementById("drawingArea");
      if(this.canvas){
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.$socket.emit("clearCanvas");
      }
    },

    changeColor(){
      this.ctx.strokeStyle =this.color;
      this.$socket.emit("changeColor",this.color);
    },
    changeLineWidth(){
      this.ctx.lineWidth =this.lineWidth;
      this.$socket.emit("changeLineWidth",this.lineWidth);
    },
    changeToolType(toolType){
      this.tooltype = toolType;
      this.$socket.emit("changeToolType",toolType);
    },



    setPosition(e) {
      this.canvas = document.getElementById("drawingArea");
      if(this.canvas){
      this.pos.x = e.clientX - this.canvas.offsetLeft;
      this.pos.y = e.clientY-this.canvas.offsetTop;
      }
    },
    setReceiverPosition(e) {
      this.canvas = document.getElementById("drawingArea");
      if(this.canvas){
      this.pos.x = (e.clientX*this.canvas.width) - this.canvas.offsetLeft;
      this.pos.y = (e.clientY*this.canvas.height)-this.canvas.offsetTop;
      }
    },


    setupCanvas() {
      this.canvas = document.getElementById("drawingArea");
      if (this.canvas) {
      this.ctx = this.canvas.getContext("2d");
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;

        this.ctx.lineWidth = this.lineWidth; // width of line
        this.ctx.lineCap = "round"; // rounded end cap
        this.ctx.strokeStyle = this.color; // hex color of line

        this.canvas.addEventListener('mousedown',  (evt)=> {
          if(!this.isAdmin) return;
          this.canvasPressed = true;
          this.setPosition(evt);
          this.ctx.beginPath();
          this.ctx.moveTo(this.pos.x, this.pos.y);
          this.$socket.emit('draw-mousedown', { clientX: evt.clientX/this.canvas.width, clientY: evt.clientY/this.canvas.height});
        });
        
        this.canvas.addEventListener('mouseup',  (evt) =>{
          if(!this.isAdmin) return;
          this.canvasPressed = false;
          this.$socket.emit('draw-mouseup', {});
          console.log("mouseup:",evt)
        });
        
        this.canvas.addEventListener('mousemove',  (evt)=> {
          if(!this.isAdmin) return;
          if (this.canvasPressed) {
            this.setPosition(evt);

            if(this.tooltype=='pen') {
                this.ctx.globalCompositeOperation = 'source-over';
            } else {
                this.ctx.globalCompositeOperation = 'destination-out';
            }
            this.ctx.lineTo(this.pos.x, this.pos.y);
            this.ctx.moveTo(this.pos.x, this.pos.y);
            this.ctx.stroke();
            this.$socket.emit('draw-mousemove', { clientX: evt.clientX/this.canvas.width, clientY: evt.clientY/this.canvas.height});
          }
        });

        this.$socket.on('draw-mousedown',  (evt)=> {
            this.canvasPressed = true;
            this.setReceiverPosition(evt);

            this.ctx.beginPath();
            this.ctx.moveTo(this.pos.x, this.pos.y);
        });
        this.$socket.on('draw-mouseup',  (evt) =>{
            console.log('draw event', evt);
            this.canvasPressed = false;
        });
        this.$socket.on('draw-mousemove',  (evt)=> {
             if (this.canvasPressed) {
               this.setReceiverPosition(evt);
               if(this.tooltype=='pen') {
                    this.ctx.globalCompositeOperation = 'source-over';
                } else {
                    this.ctx.globalCompositeOperation = 'destination-out';
                }
                this.ctx.lineTo(this.pos.x, this.pos.y);
                this.ctx.moveTo(this.pos.x, this.pos.y);
                this.ctx.stroke();
            }           
        });
    }
  }
  }

};
</script>

<style scoped>

.countdown {
  color: black;
  font-size: 3em;
  font-weight: bold;
}

#timer {
  margin-bottom: 20px;
  animation: pulse 1s infinite;
}

.progress-bar {
  width: 100%;
  height: 30px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #ff416c 0%, #ff4b2b 100%);
  transition: width 0.2s;
  border-radius: 15px 0 0 15px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.team-name{
  color: var(--dark-color);
  font-weight: bold;
}
.game-link-container {
  display: flex;
  align-items: center;
}

.game-link {
  font-size: 1.2rem;
  margin-right: 10px;
  cursor: pointer;
}

.copy-button {
  font-size: 1rem;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #218838;
}

.copy-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
</style>