<template>
  <div class="homePageBody">
    <div class="musicalSection defaultLM p-1">
        <div class="SectionTitle">Musical Meditation</div>
        <div class="sectionContainer" >        
        <div class="grid activity_list_Items" >
             
             <fragment 
               v-for="(detail,index) in musicalMeditationData"
               :key="index">
               <div
               class="item_card shadow border-radius-md border card-1 m-auto"
               :title="detail.title"
               >
               <div class="cardThumbnail-cointainer">
                <iframe class="cardVideoThumbnail" style=""  :src="detail.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="card-body p-2">
                   <div class="videoTitle">{{detail.title}}</div>
                   <div class="videoBy">{{detail.videoBy}}</div>
                   <div class="videoDetails">
                   <div class="videoDuration">Duration: {{detail.duration}}</div>
                   <!-- <span class="dot"></span>
                   <div class="videoUploadDate">{{detail.uploadDate}}</div> -->
               </div>
               </div>
           
               </div>
               </fragment>
           </div>
        </div>
    </div>
    <div class="guidedSection defaultLM">
        <div class="SectionTitle">Guided Meditation</div>

        <div class="grid activity_list_Items" >
             
             <fragment 
               v-for="(detail,index) in guidedMeditationData"
               :key="index">
               <div
               class="item_card shadow border-radius-md border card-1 m-auto"
               :title="detail.title"
               >

               <div class="cardThumbnail-cointainer">
                <iframe class="cardVideoThumbnail" style=""  :src="detail.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

              
               <div class="card-body p-2">
                   <div class="videoTitle">{{detail.title}}</div>
                   <div class="videoBy">{{detail.videoBy}}</div>
                   <div class="videoDetails">
                   <div class="videoDuration">Duration: {{detail.duration}}</div>
                   <!-- <span class="dot"></span>
                   <div class="videoUploadDate">{{detail.uploadDate}}</div> -->
               </div>
               </div>
           
               </div>
               </fragment>
           </div>
       </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      musicalMeditationData:[{
        url:"https://www.youtube.com/embed/5hjnNq-JG-8?modestbranding=1&rel=0&showinfo=0",
        title:"Isha Relax Music",
        videoBy:"ISha",
        duration:"19 Mins",
        uploadDate:"2 days ago",
      },
      {
        url:"https://www.youtube.com/embed/B3reosNclc4?modestbranding=1&rel=0&autohide=1&showinfo=0",
        title:"Traditional Healing Music",
        videoBy:"ISha",
        duration:"21 Mins",
        uploadDate:"6 days ago",},
      {
        url:"https://www.youtube.com/embed/1P59sBpb3xg?start=19&modestbranding=1&rel=0&showinfo=0",
        title:"Soothing instrumental music",
        videoBy:"Musiqaa",
        duration:"16 Mins",
        uploadDate:"7 days ago",},
      {
        url:"https://www.youtube.com/embed/aIIEI33EUqI?start=125&modestbranding=1&rel=0&showinfo=0",
        title:"Super Deep",
        videoBy:"Yellow Brick",
        duration:"13 Mins",
        uploadDate:"6 days ago",},
      {
        url:"https://www.youtube.com/embed/dxGU80Ny0JQ?modestbranding=1&rel=0&showinfo=0",
        title:"Relaxing Theta Waves",
        videoBy:"MusicMindMagic",
        duration:"15 Mins",
        uploadDate:"19 days ago",},
      // {
      //   url:"https://www.youtube.com/embed/9BD1y0TOk3o?start=4&modestbranding=1&rel=0&showinfo=0",
      //   title:"Flute Meditation",
      //   videoBy:"Buddha's Lounge",
      //   duration:"20 Mins",
      //   uploadDate:"10 days ago",},
      {
        url:"https://www.youtube.com/embed/TXSb_OIMNQg?start=16&modestbranding=1&rel=0&showinfo=0",
        title:"Healing Music Deep",
        videoBy:"Geethanjali - Indian Classical Music",
        duration:"14 Mins",
        uploadDate:"05 days ago",},
      {
        url:"https://www.youtube.com/embed/D1lH55N72U0?start=4&modestbranding=1&rel=0&showinfo=0",
        title:"Peaceful Morning Music",
        videoBy:"Soothing Relaxation",
        duration:"10 Mins",
        uploadDate:"08 days ago",},
      ],
      guidedMeditationData:[
      //   {
      //   url:"https://www.youtube.com/embed/a03L4YBIrTs?modestbranding=1&rel=0&showinfo=0",
      //   title:"Your Presence is Powerful",
      //   videoBy:"Great Meditation",
      //   duration:"10 Mins",
      //   uploadDate:"9 days ago",
      // },
      // {
      //   url:"https://www.youtube.com/embed/iBEuLJeA_oI?modestbranding=1&rel=0&showinfo=0",
      //   title:"Powerful Manifesting",
      //   videoBy:"Great Meditation",
      //   duration:"11 Mins",
      //   uploadDate:"3 days ago",},
      {
        url:"https://www.youtube.com/embed/EwQkfoKxRvo?modestbranding=1&rel=0&showinfo=0",
        title:"Isha Kriya",
        videoBy:"Sadhguru",
        duration:"19 Mins",
        uploadDate:"1 Month ago",},
      {
        url:"https://www.youtube.com/embed/O-6f5wQXSu8?modestbranding=1&rel=0&showinfo=0",
        title:"Meditation For Anxiety",
        videoBy:"Goodful",
        duration:"10 Mins",
        uploadDate:"15 days ago",},
      {
        url:"https://www.youtube.com/embed/QXx2IlYJg2E?modestbranding=1&rel=0&showinfo=0",
        title:"A Fresh Start - Push the Reset Button!",
        videoBy:"MindfulPeace",
        duration:"13 Mins",
        uploadDate:"11 days ago",},
      {
        url:"https://www.youtube.com/embed/fX4j-DrvNOI?modestbranding=1&rel=0&showinfo=0",
        title:"Balance Your Emotions",
        videoBy:"Peace & Love",
        duration:"15 Mins",
        uploadDate:"12 days ago",},
      // {
      //   url:"https://www.youtube.com/embed/Rx5X-fo_fEI?modestbranding=1&rel=0&showinfo=0",
      //   title:"Go Easy on Yourself",
      //   videoBy:"MindfulPeace",
      //   duration:"15 Mins",
      //   uploadDate:"9 days ago",},
      // {
      //   url:"https://www.youtube.com/embed/Qa3uYValbWc?modestbranding=1&rel=0&showinfo=0",
      //   title:"Meditation for Gratitude",
      //   videoBy:"Peace & Love",
      //   duration:"15 Mins",
      //   uploadDate:"6 days ago",},
      ]
    };
  },
}
</script>








<style>
.meditationPageBody{
  margin-top: 10vh;
  height: 90vh;
  position: absolute;
  width: 100%;
}
.defaultLM{
    margin-left: 77px;
    margin-right: 77px;
}
.cardVideo{
  border-radius: 15px !important;
}
.activity_list_Items{
  display: flex;
  flex-wrap: wrap;
}
.SectionTitle{
    color: var(--primary-color);
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}
.videoTitle{
  font-size: 1.25rem;
  font-weight: 600;
}
.videoBy{
  font-size: 1rem;
    font-weight: 400;
}
.videoDetails{
  display: flex;
  font-size: .75rem;
  font-weight: 400;
}
.dot {
  height: 0.75rem;
  width: 0.75rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}
.videoWrapper{
  overflow: hidden;
}
.cardVideoThumbnail{
  width: 350px;
  height: 190px;
  border-radius: 12px;
}
</style>