<template>
  <div>
 <BlockUI v-if="loading" :message="loadingMsg">
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
  </BlockUI>
    <b-form @submit="onSubmit" class="reserForm" v-if="showResetDiv">
      <b-form-group
        id="input-group-1"
        label="New Password"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.newPass"
          type="password"
          placeholder="New Password"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Confirm Password"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.confirmPass"
          type="password"
          placeholder="Confirm Password"
          required
        ></b-form-input>
      </b-form-group>


      <b-button class="resetPasswordBtn" type="submit" variant="primary">Reset Password</b-button>
    </b-form>

    <div v-if="showSuccessDiv" class="mt-5" style="width:100% !important;text-align:center">
      <div style="height: 6rem;"><b-icon icon="check2-circle" variant="success" scale="7"></b-icon></div>
      <div style="font-size:3rem">Your password has been reset sucessfully.</div>
      <div ><b-button style="width:25% !important" @click="$router.push('/')" class="resetPasswordBtn m-4" variant="primary">Go to Login</b-button></div>
    </div>
  <b-modal id="error-modal" ref="error-modal" size= 'sm'  buttonSize= 'sm'  headerClass='p-2 border-bottom-0'  footerClass='p-2 border-top-0' hide-header-close  ok-only centered title="Oops.">
    <p class="my-4">{{errMsg}}. Please try again</p>
  </b-modal>
  </div>
</template>

<script>
import {verifyAccount,resetPassword} from "../api/index";
  export default {
    data() {
      return {
        loadingMsg:"Please wait",
        loading:false,
        form: {
            newPass:"",
            confirmPass:""
        },
        show:false,
        showResetSuccess:false,
        errMsg:""
      }
    },
    computed:{
      showResetDiv(){
        return this.show;
      },
      showSuccessDiv(){
        return this.showResetSuccess;
      }
    },
    mounted() {
        this.verifyLink();
    },
    methods: {
      verifyLink(){
        this.loading=true;
        let urlData = this.$route.query;
        this.$store.commit("setAccessToken", urlData.token);
        verifyAccount(urlData)
                .then((resp) => {
                  this.loading=false;
                  this.show=true;
                  console.log("ververifyAccount",resp);
                //   this.$store.commit("setLoggedInUserInfo", resp.data.data);
                })
                .catch((error) => {
                  this.loading=false;
                  console.log("verify error::",error.response.data);
                  this.$refs['error-modal'].show();
                  this.errMsg=error.response.data;
                })
      },
      onSubmit(event) {
        event.preventDefault()
        this.loading=true;
        let payload={
          password : this.form.confirmPass
        }
        resetPassword(payload)
          .then((resp) => {
            this.loading=false;
            this.show=false;
            this.showResetSuccess=true;
            console.log("resetPassword",resp);
          //   this.$store.commit("setLoggedInUserInfo", resp.data.data);
          })
          .catch((error) => {
            this.loading=false;
            console.log("resetPassword error::",error.response.data);
            this.$refs['error-modal'].show();
            this.errMsg=error.response.data;
          })

      },
    }
  }
</script>

<style scoped>
.reserForm{
    width: 25%;
    margin: auto;
    margin-top: 10rem;
}
.resetPasswordBtn{
    width: 100% !important;
}
</style>