<template>
  <div class="join-room-container">
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div v-if="invalidRoom" class="invalid-room">{{ errMsg }}</div>
    <b-card v-else class="join-room-card" border-variant="primary">
      <b-form @submit="submit">
        <b-row class="my-1">
          <b-col>
            <label for="input-none" class="form-label">Full Name:</label>
            <b-form-input
              id="input-none"
              v-model="form.userName"
              type="text"
              :state="form.userName.length > 0 ? true : null"
              placeholder="Enter full name"
              required
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3" v-if="isTeamMode">
          <b-col>
            <label for="team-select" class="form-label">Select Team:</label>
            <b-dropdown
              id="team-select"
              class="w-100"
              variant="outline-primary"
              :text="getTeamName"
              block
              menu-class="w-100"
            >
              <b-dropdown-item
                v-for="team in form.teams"
                @click="setTeam(team)"
                :key="team.id"
                >{{ team.name }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <p v-if="isInvalid" style="color: red; text-align: center">
          {{ errMsg }}
        </p>
        <b-button
          pill
          class="mt-3 btn-layout-2 color-2"
          variant="primary"
          block
          size="lg"
          type="submit"
          >Join Room</b-button
        >
      </b-form>
    </b-card>
    <b-modal
      id="error-modal"
      ref="error-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title="Oops."
    >
      <p class="my-4">{{ errMsg }}. Please try again</p>
    </b-modal>
  </div>
</template>

<script>
import { getRoomData } from "../api/index";
export default {
  data() {
    return this.initialState();
  },
  mounted() {
    //join Event
    this.$socket.on("joined", (data) => {
      if (data) {
        this.$store.commit("setGameCode", data.gameCode);
        this.$store.commit("setQuestionLength", data.questionLength);
        this.$store.commit("setGameConfig", data.gameConfig);
        this.$store.commit("setUserGameConfig", data.userGameConfig);
        let path = this.getEventPath(data.evtCode);
        this.$router.push(path);
      }
    });

    this.getRoomDetails();
  },
  computed: {
    getTeamName() {
      return this.form.teamDetails.name || "Select Team";
    },
    isTeamMode() {
      return this.form.enableTeamMode;
    },
  },
  methods: {
    initialState() {
      return {
        invalidRoom:false,
        loading: false,
        loadingMsg: "Please Wait...",
        isInvalid: false,
        errMsg: "",
        form: {
          userName: "",
          teamDetails: { id: -1 },
          enableTeamMode: false,
          teams: [],
          roomCode: this.$route.params.roomId,
        },
      };
    },

    resetData() {
      Object.assign(this.$data, this.initialState());
    },
    getRoomDetails() {
      this.loading = true;
      getRoomData(this.form.roomCode)
        .then((resp) => {
          if (resp && resp.data && resp.data.data.length>0) {
            const res_data = resp.data.data[0];
            const gameConfig = ("GameConfig" in res_data)?JSON.parse(res_data.GameConfig):{};
            this.form.enableTeamMode = gameConfig.enableTeamMode ||false;
            this.form.teams = gameConfig.teams || []; // Assuming `teams` is a separate property
            this.loading = false;
          } 
        })
        .catch((err) => {
          console.log("Error",err);
          this.loading = false;
         
          this.errMsg =err.response.data.message;
          this.invalidRoom = true;
        });
    },
    setTeam(teamData) {
      this.form.teamDetails = teamData;
    },
    submit(event) {
      event.preventDefault();
      const isVaild = this.validateData();
      if (isVaild) {
        const isJoined = this.handleJoinRoom(this.form);
        if (isJoined) {
          this.resetInvalid();
        }
      }
    },
    validateData() {
      if (this.form.enableTeamMode) {
        if (this.form.teamDetails.id > -1) {
          return true;
        } else {
          this.isInvalid = true;
          this.errMsg = "Please Select Team";
          return false;
        }
      } else {
        return true;
      }
    },
    handleJoinRoom(data) {
      this.loading = true;
      let username = data.userName;
      let room = data.roomCode;
      let userData = data;
      let eventCode = "";
      this.$socket.emit(
        "join",
        { username, room, eventCode, userData },
        (error) => {
          let result = false;
          if (error) {
            this.isInvalid = true;
            this.errMsg = error;
          } else {
            this.$store.commit("setPlayerName", username);
            result = true;
          }
          this.loading = false;
          return result;
        }
      );
    },
  },
};
</script>

<style scoped>
.join-room-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, var(--primary-color) 0%, #2a5298 100%);
  background-image: url("../assets/join_bg1.png");
  background-size: cover;   
}

.join-room-card {
  min-width: 40vw;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.b-dropdown .dropdown-menu {
  width: 100%;
}

.b-dropdown-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.b-dropdown-item:hover {
  background-color: #e9ecef;
}
.invalid-room{
    color: white;
    font-weight: bold;
    font-size: 50px;
}
</style>
