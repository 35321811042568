<template>
<div class="magicPicturePage">
  <div class="header header-layout-2">
    <div class="header-layout-2-logoSection">
         <img
            class="header-logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="header-logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
     <div class="header-layout-2-middleSection" >
       <div>
         Game code : {{$store.state.gameCode}} 
      </div>
    </div>
    <div class="header-layout-1-rightHeader" >
      <button v-if="questionData" class="btn-layout-2 createDatasetBtn leaveGameBtn" @click="leaveLobby">Leave</button>
      <div v-if="!questionData" class="header_nav_link" style="margin-left:20px" @click="$router.push('/homepage')">Home</div>
    </div>
  </div>
  <div class="actvity-layout-1">
    <div class="actvity-layout-1-question-section">
      <div class="question-container"> 
        <div>
          {{questionData}}
        </div>
      </div>
      <div class="question-middle-container">
        <img v-if="pictureUrl"
            class="pictureImg"
            :src="pictureUrl"
        />
      </div>
      <div v-if="validateAdmin" class="actvity-layout-1-gameControlBtns">
        <button :disabled="currentQuestionNo<=1" class="btn-layout-2 color-2 game-btn-1" :class="{ 'disableBtn': currentQuestionNo<=1 }" @click="getPreviousQuestion()"><b-icon icon="chevron-left" font-scale="1" aria-hidden="true"></b-icon></button>
        <button class="btn-layout-2 color-2 game-btn-2" @click="resetBuzzer()">Reset Buzzer</button>
        <button class="btn-layout-2 color-2 game-btn-3" @click="getPicture()">Play</button>
        <button class=" btn-layout-2 color-2 game-btn-4"  @click="showAnswerToAllPlayers()">Reveal Answer</button>
        <button :disabled="currentQuestionNo>=$store.state.questionLength" :class="{ 'disableBtn': currentQuestionNo>=$store.state.questionLength }" class="btn-layout-2 color-2 game-btn-1 gird-end" @click="getNextQuestion()"><b-icon icon="chevron-right" font-scale="1" aria-hidden="true"></b-icon></button>
      </div>
      <div class="actvity-layout-1-answerSection" v-if="showAnswer && validateAdmin || showAnswer && showAnswerToAll">
        Answer : {{showAnswer}}
      </div>
    </div>
    <div class="leaderBoard-section-layout-1" v-if="questionData">
      <div>
        <div v-if="validateAdmin" class="">
          <input @input="searchPlayer($event)" type="text" ref="playerName" class="input-style-2 font-1_2"  placeholder="Search player">
        </div>
      </div>
      
        <div class="leaderBoard-div">
          <div class="leaderBoard-header">
            LeaderBoard
          </div>
        <div class="leaderBoardList">
          <ul class="rankingList">
            <li class="playerRanking" v-for="(val,index) in filteredPlayernamelist" :key="index">
              <div class="playerRank">{{index+1}}.</div>
              <div class="playerName">{{val.name}}</div>
              <div ><b-icon v-if="validateAdmin" @click="decreaseScore(val)" icon="dash-circle" font-scale="1" aria-hidden="true"></b-icon></div>
              <div class="playerScore">{{val.Score}}</div>
              <div ><b-icon v-if="validateAdmin" @click="increaseScore(val)" icon="plus-circle"  font-scale="1" aria-hidden="true"></b-icon></div>
            </li>
          </ul>  
        </div>
      </div>


      <div class="buzzer-div">
        <div v-if="firstBuzzerPressedBy.length>0" class="buzzerpressedby">{{firstBuzzerPressedBy}}</div>
       <button v-else class="pushable" id="buzzerBtn" @click="buzzer" :disabled="disableBuzzerBtn">
          <span class="buzzerShadow"></span>
          <span class="buzzerEdge"></span>
          <span class="buzzerFront">
            <span class="buzzerText">Buzzer</span>            
          </span>
        </button>
      </div>
    </div>

    <div class="scoreCard-div">
      <div v-if="!validateAdmin" class="scoreCard">
          <div>Your Score</div>
          <div class="t-color-2">{{getScore}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

   data() {
    return {
      loginplayername:this.$store.state.playerName,
      isAdmin:false,
      playernamelist:[],
      filteredPlayernamelist:[],
      firstBuzzerPressedBy:"",
      currentQuestionNo:false,
      questionData:false,
      pictureUrl:false,
      showAnswer:false,
      showAnswerToAll:false,
      playerScore:0,
    };
  },
  created() {
  },
  computed: {
    getImgUrl() {
      // return "https://firsttestappserver.azurewebsites.net/"+this.pictureUrl;
      return this.$store.state.api_Url+this.pictureUrl;
    },

    validateAdmin(){
      return (this.isAdmin || this.$store.state.superAdmin);
    },
    getScore() {
      return this.playerScore;
    },
    disableBuzzerBtn(){
      return this.firstBuzzerPressedBy.length>0;
    }

  },
  watch: {
    '$store.state.playerName'(newVal) {
      this.loginplayername = newVal;
    }
  },
  mounted() {
   
   this.$socket.on("questionNo", (data) => {
    console.log("qno:",data);
    this.currentQuestionNo=data;
   });
   this.$socket.on("question", (questionData) => {
    console.log("question data::",questionData)
    this.questionData=questionData;
   });
   this.$socket.on("playPicture", (pictureUrl) => {
    this.pictureUrl=pictureUrl;
   });
   this.$socket.on("showAnswer", (showAnswer) => {
    this.showAnswer=showAnswer;
   });
   this.$socket.on("showAnswerToAll", (showAnswerToAll) => {
    this.showAnswerToAll=showAnswerToAll;
   });
   this.$socket.on("pressbuzzerBy", (data) => {
    this.firstBuzzerPressedBy=data;
   });
   this.$socket.on("resetBuzzerclient", (data) => {
    this.firstBuzzerPressedBy=data;
   });

  //  if(this.questionData ==false){
  //   this.$router.push("/gamesdashboard")
  //  }
   //lobby

  this.$socket.on("roomData", (data) => {
    this.isAdmin = (data.adminId === localStorage.getItem("user_socket_id"))?true:false;
    let sortedData = data.users.sort(function (a, b) {return b.Score - a.Score;});
    this.playernamelist = this.filteredPlayernamelist = sortedData;
    if(!this.isAdmin)this.updateScore();
   });
  },
  methods: {
    getNextQuestion() {
      this.$socket.emit("nextQuestion", (this.currentQuestionNo + 1));
      this.resetBuzzer();
    },
    getPreviousQuestion() {
      this.$socket.emit("nextQuestion", (this.currentQuestionNo - 1));
      this.resetBuzzer();
    },
    getPicture() {
      this.$socket.emit("playPicture", this.currentQuestionNo);
    },
    buzzer(){
      console.log("buzzerpressed")
      this.$socket.emit("pressbuzzer", this.loginplayername);
    },
    resetBuzzer(){
      // this.$socket.emit("resetbuzzer");
    },
    showAnswerToAllPlayers(){
      this.$socket.emit("showAnswerToAll");
    },
    increaseScore(playerData){
      let pDetails=playerData;
      pDetails.Score +=100;
      this.$socket.emit("updatePlayerScore",pDetails);
    },
    decreaseScore(playerData){
      let pDetails=playerData;
      pDetails.Score -=100;
      this.$socket.emit("updatePlayerScore",pDetails);
    },
    searchPlayer(event){
    var input = event.target.value;
    let filterpPlayers = this.playernamelist.filter((player) => {
        return (
          player.name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
      this.filteredPlayernamelist = filterpPlayers;
    },
    updateScore() {
      console.log("update....")
       var foundIndex = this.playernamelist.findIndex(
            (player) => player.id ==  localStorage.getItem("user_socket_id")
        );
      if(this.playernamelist){
          this.playerScore = this.playernamelist[foundIndex].Score;
        }
    },
  }
}
</script>
<style>
.magicPicturePageBody{
  display: flex;
  margin-top: 10vh;
  min-height:90vh;
  height: auto;
  position: absolute;
  width: 100%;
  background-image: url("../assets/gameBg.png");
  /* background-size: 100% 100%; */
  background-size: cover !important;
}
.questionSection{
  margin-left: 77px;
}
.question{
  background: white;
  border: 1px solid var(--primary-color);
  border-radius: 40px;
  height: 50pt;
  width: 520pt;
  text-align: center;
  
  font-size: 31.5pt;
  letter-spacing: 1px;
  color: var(--primary-color);
  margin-top: 40px;
}
.scoreCard-div{
   height: 100%;
   padding-top: 50%;
}
.scoreCard{
  height: 33%;
  background: white;
  border: 1px solid var(--primary-color);
  border-radius: 40px;
  text-align: center;
  
  font-size: 2.5vw;
  letter-spacing: 1px;
  color: var(--primary-color);
}
.pictureSection{
height: 360px !important;
}
.leaderboardSection{
  width: 400px !important;
  height: 280px !important;
}
.buzzerSection{
  margin-left: 100px;
}
.buzzerBtn{
  width: 50%;
  height: 100%;
  background-color: #B8DF10;
  border-radius: 50%;
  box-shadow: 1px 1px 2px white, 0 0 30px blue, 0 0 5px darkblue;
  text-align: center;
  color: white;
}
.buzzerBtn:disabled,
.buzzerBtn[disabled]{
  opacity: 50% !important;
}
.playerRanking{
  display: grid;
  grid-template-columns: 10% 60% 10% 10% 10%;
  padding: 1%;
  font-size: 1.5vw;
}
.playerScore{
  font-weight: bold;
  color: #B8DF10;
}
.rankingList{
  padding: 0px;
  height: 100%;
  overflow-y: auto;
}
.playerName{
  width: 15.7vw;
  overflow: hidden;
  text-align: left;
}

.buzzerpressedby{
  font-size: var(--text-lg);
  
  font-weight: bold;
  position: relative;
  justify-content: center;
  text-align: center;
  color: var(--primary-color);
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoreSection{
  height: 150pt;
  width: 130pt;
  margin-left: 50px;

}
.t-color-2{
  color: #B8DF10;
}

.pictureImg{
max-width: 100%;
    max-height: 100%;
}
.gameControlBtns{
  text-align: center;
}

.controlBtn{
  width: 165px;
  padding: 10px;
  margin-left: 10px;
  margin-top: 20px !important;
}

.answerSection{
  -webkit-text-stroke: 1px #212529;
  margin-top: 10px;
}
.searchPlayer{
  margin-top: 40px;
}
.questionbtn{
  width: 65px !important;
}

/*scroll bar css */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.leaveBtn{
    width: 165px;
    padding: 10px;
    margin-left: 12rem;
    margin-top: -20px !important;
    background-color: red;
}
.leaveGameBtn{
  background: linear-gradient(to top, #dc3545 0%, #ff5454 100%) !important;
}
.actvity-layout-1{
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 55% 28% 12%;
    align-items: center;
    gap: 2rem;
    text-align: center;
    background-image: url("../assets/planeWhiteShapeBg.png");
    background-size: cover !important;

    padding-top: 10vh;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 6vh;
}
.question-container{
    background: white;
    border: 1px solid var(--primary-color);
    border-radius: 40px;
    min-height: 50%;
    
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: var(--primary-color);
    display: grid;
    align-items: center;
    width: 100%;
}

.actvity-layout-1-question-section{
    display: grid;
    align-items: center;
    grid-template-rows: 15% 60% 15% 10%;
    /* grid-template-rows: 0.5fr 2fr 0.5fr 0.2fr; */
    min-width: 0px;
    min-height: 0px;
    grid-auto-flow: row;
    width: 100%;
    height: 100%;
}
.question-middle-container{
    border: 1px solid var(--primary-color);
    border-radius: 40px;
    max-height: 50vh;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    background: white;
    padding: 2%;
}

.actvity-layout-1-gameControlBtns{
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;
  grid-template-columns: auto 1fr 1fr 1fr auto;
}
.actvity-layout-1-controlBtn{
  /* margin-left: 0% !important;
  min-width: auto !important;
 height: 8vh;
    font-size: 1vw !important; */
}
.actvity-layout-1-answerSection{
  -webkit-text-stroke: 1px #212529;
    font-size: 1vw !important;
}
.color-2{
  background: linear-gradient(to top, var(--primary-color) 0%, #5614ac 100%) !important;
}
.bg-color-3{
  background: linear-gradient(to top, #95b410 0%, #c0e815 100%) !important;
}
.leaderBoard-section-layout-1{
    display: grid;
    align-items: center;
    grid-template-rows: 15% 50% 35%;
    grid-auto-flow: row;
    width: 100%;
    height: 100%;
}
.leaderBoard-div{
   display: grid;
    grid-template-rows: 10% 90%;
    width: 100%;
    height: 42vh;
    background: white;
    border: 1px solid var(--primary-color);
    border-radius: 40px;
    
    font-size: 1vw;
    letter-spacing: 1px;
    color: var(--primary-color);
    align-items: center;
}
.leaderBoard-header{
 font-size: 2vw;
}
.searchBar{
  width: 100% !important;
}
.buzzer-div{
  width: 100%;
  height: 100%;
  padding: 5%;
}
.leaderBoardList{
  height: 100%;
  padding-bottom: 8%;
}



.grid-end{
  grid-area: d;
}
  .game-btn-1{
    width: 7vh !important;
    height: 7vh !important;
  }


  
.pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    height: 24vh;
    width: 24vh;
    border-radius: 50%;
  }
  .buzzerShadow {
    height: 24vh;
    width: 24vh;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .buzzerEdge {
    height: 24vh;
    width: 24vh;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 103%;
    background: linear-gradient(
      to left,
      #2c3503 0%,
      #6a8106 8%,
      #556708 92%,
      #2c3503 100%
    );
  }
  .buzzerFront {
    align-items: center;
    justify-content: center;
    height: 24vh;
    width: 24vh;
    border-radius: 50%;
    display: flex;
    position: relative;
    padding: 12px 42px;
    font-size: 1.25rem;
    color: white;
    background: #B8DF10;
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .pushable:hover {
    filter: brightness(110%);
  }
  .pushable:hover .buzzerFront {
    transform: translateY(-6px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .buzzerFront {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  .pushable:hover .buzzerShadow {
    transform: translateY(4px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .buzzerShadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
</style>
