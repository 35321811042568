<template>
  <div class="Home">
    <!--This Header needs changed completely  -->
    <div
      class="header-layout-1 home-header navlink-layout-1 bg-wt"
    >
      <div
        class="header-layout-1-logoSection home-logo-header"
        @click="$router.push('/')"
      >
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img
 
          class="header-logoIcon"
          src="../assets/loginPageLogo.png"
        />
        <!-- <img v-else class="header-logoImg" src="../assets/loginPageLogo.png" /> -->
      </div>

      <div class="header-layout-1-navLinks">
      <div class="nav-link" @click="$router.push('/about')">About</div>
      <div class="nav-link" @click="$router.push('/contact')">Contact</div>
    </div>
      <div class="header-layout-1-rightHeader home-right-header">
        <div class="home-login-div">
          <button class="home-login-btn" @click="$router.push('/login')">
            Login
          </button>
        </div>
      </div>

        <div class="home-nav-toggle" @click="toggleNavMenu">
          <b-icon icon="list" class="rounded p-1"></b-icon>
        </div>
        <div class="mobile-nav-links" v-if="isNavMenuVisible">
        <div class="mobile-nav-link" @click="$router.push('/about')">About</div>
        <div class="mobile-nav-link" @click="$router.push('/contact')">Contact</div>
      </div>
    </div>

    <!-- page starts -->

    <div class="home-body">

      <div class="hero-container-1 h-70vh hero-bg-1">
        <div class="section-title text-center">
           About us
          </div>

        <div class="hero-subtitle  w-full quote-text">
            The ultimate platform for virtual team building. Our platform offers
            a wide range of customizable team building activities, along with
            powerful features like room creation and admin control. We believe
            in helping teams form stronger connections, increase productivity,
            and achieve impressive results.

       </div>
      </div>

      <div class="hero-container hero-bg-1">
        <div class="details-section">
          <div class="section-title">
            Our Mission
          </div>
          <div class="home-li-item text-justify">
            Our mission at TeamsMagic is to create and deliver exceptional virtual team-
            building activities that foster collaboration, improve communication, and
            strengthen team bonds in remote work environments. We aim to drive
            organizational success, promote employee growth, and boost productivity by
            providing a personalized and seamless platform for team engagement and
            corporate events.

          </div>

        </div>
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/tm_works.png" />
        </div>
      </div>
      <div class="home-footer">
        <div class="home-footer-body">
          <div class="home-footer-logo-div">
            <img class="footer-tm-log" src="../assets/logoWhite.png" />
          </div>
          <div class="home-footer-about">
            <div>A virtual platform provides employee engagement with customised
              online engagement programmes for your teams, worldwide.</div>
            <div>Follow us on 
              <b-icon icon="linkedin"  @click="openLinkedIn"></b-icon>
            </div>
            
          </div>
        </div>
        <div class="copywirte-div">
          © {{ currentYear }} Icosphere Technologies. All Rights Reserved. |
          <div class="privacy-tablink" @click="$router.push('/privacy')">
            Privacy
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "About",
    meta: [
      {
        name: "description",
        content:
          "Our platform offers a wide range of customizable team building activities, along with powerful features like room creation and admin control.",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
  },
  data() {
    return {
      currentYear: "",
      isNavMenuVisible: false
    }
  },
  mounted() {
    const d = new Date();
    this.currentYear = d.getFullYear();
  },
  methods:{
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/teamsmagic/', '_blank');
    },
    toggleNavMenu() {
      this.isNavMenuVisible = !this.isNavMenuVisible;
    },
  }
}
</script>

<style>
.h-70vh{
  height: 70vh;
}
.quote-text{
  font-weight: 800;
  font-style: italic;
}
</style>
