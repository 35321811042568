<template>
  <div>
  <BlockUI v-if="loading" :message="loadingMsg">
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
  </BlockUI>

    <b-modal
      id="import-modal"
      class="import_modal"
      centered
      title="Import Questions"
      scrollable
      no-close-on-backdrop
      @close="cancel"
      ok-only
      :ok-title="okTitle"
      @ok="confirm"
      :ok-disabled="importQuestions.length==0"
    >
      <template  #modal-title >
        <div class="import-modal-header">
          <div v-if="!showEventList" @click="setShowEventList"><b-icon icon="arrow-left-circle-fill"  variant="secondary" class="" font-scale="1" ></b-icon></div>
          <div>Import Questions</div>
        </div>
      </template>
      <div class="import_modal_body">
        <div v-if="showEventList" class="sectionContainer">
          <div class="grid activity_list_Items">
            <div v-for="event in filteredEventList" :key="event.data_code">
              <div
                class="item_card shadow border-radius-md border card-1 m-auto"
                @click="getQuestions(event)"
                :title="event.name"
              >
                <div class="cardThumbnail-cointainer">
                    <img
                        class="cardThumbnail"
                        style=""
                        :src="event.preview_url"
                        />
                </div>
                <div class="card-body p-2">
                  <div class="videoTitle">{{ event.name }}</div>
                  <div class="videoBy">Create By: {{ event.Created_By }}</div>
                  <div class="videoDetails">
                    <div class="videoDuration">
                      Event Type: {{ event.Event_Type }}
                    </div>
                    <!-- <span class="dot"></span>
                      <div class="videoUploadDate">{{event.uploadDate}}</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <infinite-loading
            @infinite="infiniteHandler"
            :identifier="infiniteId"
          >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </div>
        
        <importActivityLayout v-else :questionsProp="questions" @addImportQuestion="addImportQuestion" @removeImportQuestion="removeImportQuestion" :importQuestions="importQuestions"></importActivityLayout>

      </div>
    </b-modal>
  </div>
</template>

<script>
import {getDatasetbyId, getEventDetails} from "../../api/index"
import importActivityLayout from '../CustomCreate/importQuestionsLayout.vue';
export default {
    name: 'importData',
    components: {
        importActivityLayout
    },
    data() {
    return {
      infiniteId: +new Date(),
      loading:false,
      loadingMsg:"Please wait...",
      filteredEventList:[],
      importQuestions:[],
      showEventList:true,
      questions:[]
    }
    },  
  computed:{
    okTitle(){
      return this.importQuestions.length>0?`Import (${this.importQuestions.length})`:"Import";
    }
  },
  methods: {
    generateUniqueId() {
      const timestamp = new Date().getTime(); // Get current timestamp
      const random = Math.floor(Math.random() * 10000); // Generate random number
      return `${timestamp}${random}`; // Combine timestamp and random number to form ID
    },
    infiniteHandler($state){
            this.loading=true;
            getEventDetails()
                .then((resp) => {
                this.filteredEventList=resp.data.data;
                this.loading=false;
                $state.complete();

                })
                .catch((err) => {
                console.log("Error",err.response.data);
                this.loading=false;
                $state.complete();
                });
        },
    getQuestions(dataset){
            this.loading=true;
            getDatasetbyId(dataset.data_code)
            .then((resp) => {
                // console.log("getQuestions::",resp.data);
                resp.data.splice(0,1);
                let data =  resp.data;
                for(let i=0;i<data.length;i++){
                    data[i].id=data[i].data_code+data[i].question_no;
                    data[i].text=data[i].questionText=data[i].question;
                    if(data[i].type=='scbl'){
                     data[i].text=data[i].questionText=data[i].scribble_word;
                    }
                    if(data[i].type=='fff'){
                       data[i].options=JSON.parse(data[i].options);
                    }
                }
                this.questions=data;
                this.showEventList=false;
                this.loading=false;
                })
                .catch((err) => {
                console.log("Error",err.response.data);
                this.questions=[];
                this.loading=false;
                });
        },
    setShowEventList(){
      this.showEventList =true;
    },
    removeImportQuestion(question){
      const idx = this.importQuestions.findIndex((item)=>item.id ===question.id);
      if(idx >-1){
        this.importQuestions.splice(idx,1);
      }
    },
    addImportQuestion(question){
      this.importQuestions.push(question);
    },
    confirm() {
      this.$emit("finishImportedData",this.importQuestions);
      this.$bvModal.hide("confirmation-modal");
    },
    cancel() {
      this.showEventList =true;
      this.filteredEventList=[];
      this.importQuestions=[];
      this.showEventList=true;
      this.questions=[];
      // Handle the cancellation action
      this.$bvModal.hide("confirmation-modal");
    },
  },
};
</script>

<style>
#import-modal___BV_modal_content_{
    width: 85vw !important;
}
.import_modal_body{
    height: 65vh;
   
}
#import-modal___BV_modal_title_{
  width: 50%;
}
.import-modal-header{
  width: 100%;
  display: flex;
  gap: 2%;
}
.m-auto{
  margin: auto;
}
</style>
