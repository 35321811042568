<template>
  <div class="game-ui">
    <div class="scribble-section">
      <label class="scribble-label">Scribble word/phrase</label>
      <div class="textarea-container">
        <b-form-textarea
          id="scribble-textarea"
          maxlength="4000"
          rows="5"
          v-model="questionText"
          @input="update()"
          required
          :readonly="!enableInputs"
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    importFlagProp:Boolean
  },
  data() {
    return {
      questionText: this.value.questionText || '',
      id: this.value.id || "",
      importFlag: this.importFlagProp || false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.questionText = newValue.questionText || "";
        this.id = this.value.id || "";
        
      },
    },
  },
  computed:{
    enableInputs(){
      return !this.importFlagProp;
    }
  },
  methods: {
    update() {
      this.$emit('input-change', {
        id: this.id,
        questionText: this.questionText,
      });
    }
  }
};
</script>

<style scoped>
/* Main container */
.game-ui {
  font-family: 'Press Start 2P', cursive;
  color: #fff;
  background: linear-gradient(to right,var(--primary-color),#390879db, #390879ba ,#3908799e);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 90%;
}

/* Labels */
.scribble-label {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: block;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Textarea containers */
.textarea-container {
  margin-bottom: 20px;
}

/* Textareas */
#scribble-textarea {
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1em;
  resize: none;
  transition: transform 0.3s, box-shadow 0.3s;
}

#scribble-textarea:focus {
  outline: none;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

</style>
