<template>
  <div class="container_event_form">
    <div class="content-wrapper">
      <b-form class="form-section" @submit="nextStep">
        <h2 class="title">Event Details</h2>

        <div class="">
          <label class="p-2 text-secondary mb-0">Title</label>
          <div class="p-2">
            <b-form-input
              v-model="eventDetails.Title"
              placeholder=""
              required
            ></b-form-input>
          </div>
        </div>

        <div class="">
          <label class="p-2 text-secondary mb-0">Created By</label>
          <div class="p-2">
            <b-form-input
              v-model="eventDetails.Created_By"
              placeholder=""
              required
            ></b-form-input>
          </div>
        </div>
        <div class="">
          <label class="p-2 text-secondary mb-0">Publish Type</label>
          <b-form-group class="p-2">
            <b-button-group toggle class="d-flex">
              <b-button
                v-for="(option, index) in options"
                :key="index"
                :variant="
                  eventDetails.Publish_Type === option.value ? 'primary' : 'secondary'
                "
                @click="eventDetails.Publish_Type = option.value"
                class="d-flex align-items-center"
                :active="eventDetails.Publish_Type === option.value"
              >
                <b-icon :icon="option.icon" class="mr-2"></b-icon>
                {{ option.text }}
                <b-icon
                  icon="info-circle"
                  class="ml-2 small-info-icon"
                  :title="option.info"
                ></b-icon>
              </b-button>
            </b-button-group>
          </b-form-group>
        </div>

        <button class="submitButton-1 eventDetails-btn" type="submit">
          Next
        </button>
      </b-form>

      <div class="image-section">
        <div>
          <div class="image-box" @click="triggerFileInput">
            <input
              type="file"
              ref="fileInput"
              @change="getPreviewFile"
              style="display: none"
            />
            <div v-if="previewFileUrl" class="image-container">
              <img
                :src="previewFileUrl"
                alt="Selected Image"
                class="uploaded-image"
              />
              <span class="edit-icon" @click.stop="triggerFileInput">✎</span>
            </div>
            <div v-else class="placeholder_file">Click to upload an image</div>
          </div>
          <div v-if="err_file" class="err_text">Select a preview image</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eventTypeList: [
        {
          eventName: "Magic Picture",
          eventCode: "mp",
        },
        {
          eventName: "Fastest Finger First",
          eventCode: "fff",
        },
        {
          eventName: "Scribble",
          eventCode: "scbl",
        },
      ],

      eventDetails: {
        Title: "",
        Created_By: "",
        Event_Type: "",
        Event_Code: "",
        Preview_Url: "",
        data_code: "",
        PreviewFile: null,
        Publish_Type: "public",
      },
      previewFileUrl: null,
      err_file: "",

       // default value
      options: [
        { value: 'public', text: 'Public', icon: 'globe', info: 'Visible to everyone.' },
        { value: 'org', text: 'My Org', icon: 'people', info: 'Visible to your organization.' },
        { value: 'private', text: 'Private', icon: 'lock', info: 'Visible only to you.' }
      ]
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    getPreviewFile(event) {
      const file = event.target.files[0];
      this.eventDetails.PreviewFile = file;
      this.previewFileUrl = URL.createObjectURL(file);
    },

    isValidFile() {
      if (this.eventDetails.PreviewFile != null) {
        return true;
      } else {
        this.err_file = true;
        setTimeout(() => {
          this.err_file = false;
        }, 5000);
        return false;
      }
    },
    nextStep(event) {
      event.preventDefault();
      const validfile = this.isValidFile();
      if (validfile) {
        console.log("submit data:==", this.eventDetails);
        this.$emit("updateEventData", this.eventDetails);
      }
    },
  },
};
</script>

<style scoped>


/* Info icon styles */
.small-info-icon {
  font-size: 0.75rem !important; /* Adjust the size of the icon */
  cursor: pointer;
  margin-left: auto !important;
}

.container_event_form {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://www.transparenttextures.com/patterns/diamond-upholstery.png),
    linear-gradient(to bottom, #ffffff, var(--primary-color));
  color: #ffffff;
  background-blend-mode: overlay; /* Ensures both background color and pattern are visible */
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1200px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.form-section,
.image-section {
  padding: 20px;
  flex: 1;
}

.form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.form-group input[type="text"],
.form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #000000;
}

.dropdown {
  position: relative;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
}

.dropdown span {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f4f4f4;
}

.next-button {
  align-self: center;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.next-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.image-box img {
  object-fit: cover;
  border-radius: 10px;
  width: 390px;
  height: 225px;
}

.image-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px dashed #ddd;
  border-radius: 10px;
  background-color: #fff;
  color: #999;
  font-size: 16px;
  text-align: center;
  width: 390px;
  height: 225px;
}

.submitButton-1 {
  padding: 0.75% 1.5%;
  margin-left: 1%;
  border: none;
  border-radius: 8px;
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submitButton-1 {
  background-color: #4caf50; /* Green */
  color: white;
}

.submitButton-1:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}
.eventDetails-btn {
  height: 6vh;
}
.err_text {
  color: red;
  text-align: center;
}

/* Image uploader */
.image-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.image-box {
  width: 390px;
  height: 225px;
  border: 2px dashed rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  background: linear-gradient(
    135deg,
    var(--primary-color) 0%,
    var(--primary-color) 100%
  );
  transition: transform 0.3s, box-shadow 0.3s;
}

.image-box:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Placeholder text */
.placeholder_file {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Image container */
.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Uploaded image */
.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Edit icon */
.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s;
}

.edit-icon:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
</style>


