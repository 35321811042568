<template>
<div class="homePage">
  <div class="header">
    <div class="logoSection">
         <img
            class="logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
    <div class="rightHearder" style="display:flex">
      <div>
      Marketplace
      <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
      </div>
      <div style="margin-left:20px"> 
      Live Events
      <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
      </div>
      <div style="margin-left:20px">
      Rewards
      <b-icon font-scale="0.75" icon="lock-fill" aria-hidden="true"></b-icon>
      </div>
    </div>
  </div>
  <div class="homePageBody">
    <button @click="createLobby">Create lobby</button>
    <input type="text" ref="loobyCode">
    <button @click="joinLobby">Join lobby</button>
    <p v-for="user in userList" :key="user">{{user}}</p>
  </div>
</div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import io from "socket.io-client";

export default {
  data(){
    return{
      containerHeight:0,
      userList:[],
      socket: {}, 


    }
  },
    created() {
    this.socket = io(this.$store.state.api_Url);
  },
  mounted(){
    console.log("heighttt:  ",document.documentElement.clientHeight);
    console.log("header heighttt:  ",document.getElementsByClassName("header")[0].offsetHeight);
    this.containerHeight = document.documentElement.clientHeight - document.getElementsByClassName("header")[0].offsetHeight;


    this.socket.on("roomData", (data) => {
    console.log("roomData:",data);
   });
  //   this.socket.on("roomList", (data) => {
  //   console.log("roomList:",data);
  //  });
  },
    methods:{
     createLobby(){
      let username=this.$store.state.playerName;
       this.socket.emit("create",{ username }, error => {
        console.log("create err",error);
       });
     },
     joinLobby(){
      let username=this.$store.state.playerName;
      let room =this.$refs.loobyCode.value;
       this.socket.emit("join",{ username, room }, error => {
        console.log("Join err",error);
       });
     }
  }
}
</script>
