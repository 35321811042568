<template>
  <div class="Home">
    <!--This Header needs changed completely  -->
    <div
      class="header-layout-1 home-header navlink-layout-1"
      :class="{ 'bg-wt': isScrolled }"
      id="home-header"
    >
      <div
        class="header-layout-1-logoSection home-logo-header"
        @click="$router.push('/')"
      >
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img
 
          class="header-logoIcon"
          src="../assets/loginPageLogo.png"
        />
        <!-- <img v-else class="header-logoImg" src="../assets/loginPageLogo.png" /> -->
      </div>
      <div class="header-layout-1-navLinks">
      <div class="nav-link" @click="$router.push('/about')">About</div>
      <div class="nav-link" @click="$router.push('/contact')">Contact</div>
    </div>

      <div class="header-layout-1-rightHeader home-right-header">
        <!-- <div class="mr-3  home-login-div">
          <button class="home-login-btn" @click="showJoinModal">
            Join Room
          </button>
        </div> -->
        <div class="home-login-div">
          <button class="home-login-btn" @click="$router.push('/login')">
            Login
          </button>
        </div>
      </div>

      <div class="home-nav-toggle" @click="toggleNavMenu">
        <b-icon icon="list" class="rounded p-1"></b-icon>
      </div>
      <div class="mobile-nav-links" v-if="isNavMenuVisible">
      <div class="mobile-nav-link" @click="$router.push('/about')">About</div>
      <div class="mobile-nav-link" @click="$router.push('/contact')">Contact</div>
    </div>
    </div>

    <!-- page starts -->

    <div class="home-body">
      <!-- <div class="hero-container-1 hero-bg-img1">
        <div class="hero-title color-3">
          Unleash the Power of Teamwork
        </div>
        <div class="hero-subtitle color-3">
          Engage, Connect, and Succeed Together in the Virtual World
        </div>
        <div class="hero-subtitle color-3 hero-cta">
          <button class="btn-layout-2 bg-cl-1 cta-btn border" @click="scrolltoEmail">
            Get a Demo
          </button>
        </div>
      </div> -->

      <div class="hero-container hero-video">
        <div class="details-section ta-center">
          <div class="gameTitle stroke-white">
            Unleash the Power of Teamwork
          </div>
          <div class="gameAbout details-section-about">
            <div class="subheadline color-3">
              Engage, Connect, and Succeed Together in the Virtual World
            </div>
          </div>
          <div class="cta-btn-section">
            <div class="gameJoinBtn details-section-infoBtn cta-btn-container">
              <button class="btn-layout-2 bg-cl-1 cta-btn" @click="scrolltoEmail" style="box-shadow: 3px 4px #f3f3f3;">
                Get a Demo
              </button>
            </div>
          </div>
        </div>
        <div class="hero-section-video-div">
          <img
              v-if="!showVideo"
              class="hero-section-logo-2 promo-video-border"
              src="../assets/promo_thumnail_1.png"
              @click="loadVideo"
          />

          <video v-if="showVideo" controls autoplay class="hero-section-logo-2 promo-video-border">
            <source src="https://teamsmagicdevstorage.blob.core.windows.net/website-videos/TEAMSMAGIC WEBSITE PROMO.mp4" type="video/mp4">
              Your browser does not support the video tag.
          </video>
        </div>
      </div>


      <div style="
        padding: 5%;
        text-align: center;
      ">
        <VueSlickCarousel  v-bind="settings" :arrows="false" :dots="true">
          <div 
                v-for="event in trendingActivityList"
                :key="event.preview_url"
                >
          <img
            class="img-home-slide"
            :src="event.preview_url"
            />
        </div>
        </VueSlickCarousel>
      </div>

      <div class="home-intro-container">
        <div class="intro-container-wrapper">
          <div class="section-title intro-section-title">
            TeamsMagic
          </div>
          <div class="intro-section-body text-justify">
            The ultimate platform for virtual team building. Our platform offers
            a wide range of customizable team building activities, along with
            powerful features like room creation and admin control. We believe
            in helping teams form stronger connections, increase productivity,
            and achieve impressive results.
          </div>
        </div>

        <div class="hero-section-logo-div-3">
          <img
            class="hero-section-logo w-full"
            src="../assets/tm_cheering.png"
          />
        </div>
      </div>
      <div class="home-features-container">
        <div class="section-title">
          What You Can Have
        </div>
        <div class="cloud-design-container text-right">
          <img class="" src="../assets/cloud_design.png" />
        </div>
        <div class="features-container">
          <div class="feature-item item_card shadow-2 rounded-10 border p-2">
            <div class="feature-icon-div">
              <b-icon icon="controller" ></b-icon>
            </div>
            <div class="feature-title">
              Diverse Themed Games & Activities
            </div>
            <div class="feature-divider"></div>
            <div class="feature-body">
              Engage in a variety of themed games and activities. Choose from a
              curated collection that entertains and inspires.
            </div>
          </div>
          <div class="feature-item item_card shadow-2 rounded-10 border p-2">
            <div class="feature-icon-div">
              <b-icon icon="house-door" ></b-icon>
            </div>
            <div class="feature-title">
              Unlimited Rooms
            </div>
            <div class="feature-divider"></div>
            <div class="feature-body">
              Foster collaboration with dedicated virtual rooms. Connect and
              collaborate regardless of location.
            </div>
          </div>
          <div class="feature-item item_card shadow-2 rounded-10 border p-2">
            <div class="feature-icon-div">
              <b-icon icon="gear" ></b-icon>
            </div>
            <div class="feature-title">
              Create Your Own Custom Activities
            </div>
            <div class="feature-divider"></div>
            <div class="feature-body">
              Unleash creativity with personalized team building activities.
              Customize rules, themes, and difficulty levels for unique
              experiences.
            </div>
          </div>
          <div class="feature-item item_card shadow-2 rounded-10 border p-2">
            <div class="feature-icon-div">
              <b-icon icon="person" ></b-icon>
            </div>
            <div class="feature-title">
              Admin Control
            </div>
            <div class="feature-divider"></div>
            <div class="feature-body">
              Lead virtual team building with control. Manage activities, set
              permissions, monitor progress, and drive engagement.
            </div>
          </div>
        </div>
        <div class="cloud-design-container">
          <img class="" src="../assets/cloud_design.png" />
        </div>
      </div>

      <div class="hero-container" >
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/customize_layouts1.png" />
        </div>
        <div class="details-section">
          <div class="section-title">
            Create Your Own Custom Activities
          </div>
          <div class="home-li-item">
            TeamsMagic empowers you to design custom activities tailored to your team's unique needs and goals. Our intuitive interface allows you to create and modify activities with ease, incorporating different question types such as quizzes, scribble challenges, and magic picture games. Customize the content to align with your company culture and objectives, providing a personalized and impactful experience for your team.
          </div>
        </div>
      </div>

      <div class="hero-container" >
       
        <div class="details-section">
          <div class="section-title">
            Team Mode
          </div>
          <div class="home-li-item">
            Boost your team-building with TeamsMagic’s Team Mode. Create and customize unlimited teams, assign participants seamlessly, and enhance engagement with personalized team names. Perfect for fostering collaboration and competition, Team Mode helps strengthen team bonds and improves productivity</div>
        </div>
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/teamMode_bg2.jpeg" />
        </div>
      </div>

      <div class="hero-container" >
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/individulaMode_bg1.jpeg" />
        </div>
        <div class="details-section">
          <div class="section-title">
            Individual Mode
          </div>
          <div class="home-li-item">
            Maximize engagement with TeamsMagic’s Individual Mode. Designed for personal participation, this feature allows users to tackle activities independently while still enjoying the benefits of interactive and fun challenges. Perfect for personal development and solo tasks, Individual Mode ensures each participant remains engaged and motivated, even in a virtual setting. 
          </div>
        </div>
       
      </div>
      <div class="hero-container hero-bg-1">
        <div class="details-section">
          <div class="section-title">
            How It Works
          </div>
          <div class="home-li-item">
            <div class="t-color-2"><b-icon icon="check2-circle"></b-icon></div>
            <div class="ml-2">
              Login and create Instant Room. No downloads required
            </div>
          </div>
          <div class="home-li-item">
            <div class="t-color-2"><b-icon icon="check2-circle"></b-icon></div>
            <div class="ml-2">
              Choose from various themed games & activities
            </div>
          </div>
          <div class="home-li-item">
            <div class="t-color-2"><b-icon icon="check2-circle"></b-icon></div>
            <div class="ml-2">
              Experience the convenience of hosting your own virtual team and
              enjoy a seamless collaborative environment
            </div>
          </div>
          <div class="home-li-item">
            <div class="t-color-2"><b-icon icon="check2-circle"></b-icon></div>
            <div class="ml-2">
              That's all there is to it! Now go forth, have a ball, and make
              unforgettable memories!
            </div>
          </div>
        </div>
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/tm_works.png" />
        </div>
      </div>
      
   
      <div class="hero-container h-65vh" id="email-input-section" >
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/letsConnect.png" />
        </div>
        <div class="details-section">
          <div class="section-title">
            Lets Connect
          </div>
          <div class="home-li-item">
            Feel free to reach out for a complimentary quote or request a
            personalized demo or free trial. We're here to assist you in
            exploring our services and discovering the perfect fit for your
            needs.
          </div>
        </div>
      </div>
      <CalendlyWidget calendlyUrl="https://calendly.com/adithya_l-teamsmagic/teamsmagic-product-demo-call" />

      <div class="home-footer">
        <div class="home-footer-body">
          <div class="home-footer-logo-div">
            <img class="footer-tm-log" src="../assets/logoWhite.png" />
          </div>
          <div class="home-footer-about">
            <div>A virtual platform provides employee engagement with customised
              online engagement programmes for your teams, worldwide.</div>
            <div>Follow us on 
              <b-icon icon="linkedin"  @click="openLinkedIn"></b-icon>
            </div>
            
          </div>
        </div>
        <div class="copywirte-div">
          © {{ currentYear }} Icosphere Technologies. All Rights Reserved. |
          <div class="privacy-tablink" @click="$router.push('/privacy')">
            Privacy
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="default-modal"
      ref="default-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title=""
    >
      <p class="my-4">{{ modalMsg }}</p>
    </b-modal>
    <JoinModal :isVisible="showJoin" @update:isVisible="showJoin = $event" @update:isInvalid="isJoinInvalid = $event" @handleSubmit="handleJoinRoom($event)" :isInvalid="isJoinInvalid" :errMsg="joinErrMsg">
</JoinModal>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ScrollReveal from "scrollreveal";
import JoinModal from "../components/Modal/joinModal.vue";
import CalendlyWidget from '../components/calendly.vue';
export default {
  metaInfo: {
    title: "TeamsMagic: #1 Employee engagement platform",
    meta: [
      {
        name: "description",
        content:
          "Elevate your team's productivity with TeamsMagic! Discover seamless collaboration and communication tools that empower your workforce.",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
    script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "TeamsMagic",
            "url": "https://www.teamsmagic.com",
            "logo": "https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/TM_logo.png",
            "sameAs": [
              "https://www.linkedin.com/company/teamsmagic"
            ],
            "contactPoint": [{
              "@type": "ContactPoint",
              "telephone": "+91-94892-10341",
              "contactType": "Office"
            }]
          }
        }
      ]
  },
  data() {
    return {
      currentYear: "",
      isScrolled: false,
      demoEmail: "",
      modalMsg: false,
      loading: false,
      showJoin:false,
      isJoinInvalid:false,
      joinErrMsg:"",
      showVideo: false,      
      isNavMenuVisible: false,

      trendingActivityList:[
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic13825/1716814292852657344.jpeg"
        },
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic6236/scavenger_thumbnail.jpeg"
        },
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic6180/1718961972611036822.jpeg"
        },
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/fff-container/1714903115253223987.jpeg"
        },
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/fff-container/1715877542534951366.jpeg"
        },
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/scbl-container/1714900915426029312.jpeg"
        },
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/scbl-container/1714900110659087025.jpeg"
        },
        
        {
          preview_url:"https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic58136/thumbnail_for_Guess_the_Logo_game.jpg"
        },
      ],
      
      settings:{
          pauseOnFocus: true,
          pauseOnHover: true,
          edgeFriction: 5,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          speed: 10000,
          dots: true,
          responsive: [
            {
              breakpoint: 600, // adjust the breakpoint as needed
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        },

    };
  },


  mounted() {
  console.log("new apk->",0.4);
    const d = new Date();
    this.currentYear = d.getFullYear();

    // ScrollReveal starts
    ScrollReveal().reveal(".hero-section-logo-div-3", {
      origin: "right",
      delay: 400,
      distance: "60px",
      duration: 500,
      easing: "ease-in",
      scale: 0.85,
    });
    ScrollReveal().reveal(".details-section", {
      origin: "left",
      delay: 400,
      distance: "60px",
      duration: 500,
      easing: "ease-in",
      scale: 0.85,
    });
    ScrollReveal().reveal(".feature-item", {
      origin: "top",
      delay: 400,
      distance: "60px",
      duration: 500,
      easing: "ease-in",
      scale: 0.85,
    });

    // ScrollReveal ends

    window.addEventListener("scroll", this.handleScroll);

     //join Event 
     this.$socket.on("joined", (data) => {
      if(data){
        this.$store.commit("setGameCode", data.gameCode);
        this.$store.commit("setQuestionLength", data.questionLength);
        this.$store.commit("setGameConfig", data.gameConfig);
        let path=this.getEventPath(data.evtCode);
        this.$router.push(path);
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: { VueSlickCarousel,JoinModal, CalendlyWidget },

  methods: {
    showJoinModal(){
      this.showJoin =true;
    },
    handleJoinRoom(data){
      this.loading=true;
      let username=data.userName;
      let room =data.roomCode;
      let userData =this.$store.state.userInfo;
      let eventCode ="";
       this.$socket.emit("join",{ username, room,eventCode, userData}, error => {
        if(error){
          this.isJoinInvalid=true;
          this.joinErrMsg=error;
        }else{
          this.$store.commit("setPlayerName", username);
        }
        this.loading=false;
       });
    },
    handleScroll() {
      this.isScrolled = window.pageYOffset > 0;
    },
    scrolltoEmail() {
      var access = document.getElementById("email-input-section");
      access.scrollIntoView({ behavior: "smooth" }, true);
    },
    loadVideo() {
      this.showVideo = true;
    },
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/teamsmagic/', '_blank');
    },
    toggleNavMenu() {
      this.isNavMenuVisible = !this.isNavMenuVisible;
    },
  },
};
</script>

<style>
.h-65vh{
  height: 65vh !important;
}
.img-home-slide {
  width: 320px !important;
  height: 180px;
  margin: 10px;

  transform: rotate3d(.5, -.866, 0, 15deg) rotate(1deg);
  box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, .5), 1em 2em 3.5em -2.5em rgba(0, 0, 0, .5);
  transition: transform .4s ease, box-shadow .4s ease;
  border-radius: .5em;
}

.img-home-slide:hover {
  transform: rotate3d(0, 0, 0, 0deg) rotate(0deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3); /* Optional: adjust the shadow on hover */
}

.stroke-white{
  -webkit-text-stroke: 1px #000;
}
.hero-title {
  font-weight: 700;
  text-align: center;
  width: 60%;
  letter-spacing: 2px;
  margin: auto;
  font-size: 72px;
}
.hero-subtitle {
  width: 35%;
  text-align: center;
  margin: auto;
  font-size: var(--text-2lg);
}
.hero-cta {
  width: 20%;
}
.hero-bg-img1 {
  background-image: url("../assets/TeamsMagicBG1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-body {
  width: 100%;
  height: 100%;
}
.hero-section-logo-div-3 {
  text-align: center;
}
.ta-center {
  text-align: center !important;
}
.hero-bg-1 {
  background: linear-gradient(125deg, #f0f8ff 40%, #ffffff 100%);
}
.bg-cl-1 {
  background-color: var(--primary-color);
}
.subheadline {
  width: 65%;
  margin: auto;
}
.cta-btn-section {
  width: 100%;
}
.cta-btn-container {
  margin: auto;
  padding-top: 5%;
  width: 30% !important;
}
.cta-btn {
  padding: 5% !important;
  align-items: center;
    justify-content: center;
    display: flex;
}
.home-intro-container {
  display: grid;
  grid-template-columns: 58% 40%;
  padding: 5%;
  height: 80vh;
  align-items: center;
}
.intro-container-wrapper {
  height: 100%;
  width: 100%;
  background: #f4f4f4;
  border-radius: 50px;
}
.intro-section-title {
  padding: 5%;
  padding-bottom: 0%;
}
.home-features-container {
  display: grid;
  grid-template-rows: 14% 7% 70% 9%;
  padding: 5%;
  height: 100vh;
  align-items: center;
}
.section-title {
  font-size: var(--text-2xl);
  color: var(--primary-color);
  font-weight: bold;
  
}
.w-full {
  width: 100% !important;
}
.text-justify {
  text-align: justify;
  text-justify: inter-word;
}
.intro-section-body,
.feature-title {
  font-size: var(--text-lg);
  color: var(--primary-color);
  
  text-align: center;
  letter-spacing: 1px;
  padding: 5%;
}
.intro-section-footer {
  text-align: right;
}
.intro-section-footer {
  height: 100%;
}
.intro-section-footer-img {
  height: 100%;
}
.features-container {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 2%;
}
.cloud-design-container {
  width: 100%;
}
.text-right {
  text-align: right;
}
.feature-item {
  width: 100%;
  height: 90%;
  border-radius: 10%;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-rows: 20% 25% 2% 47%;
  gap: 2%;
}
.feature-icon-div {
  color: #b8df10;
  font-size: 3vw;
}
.feature-divider {
  border-top: 3px solid #bbb;
}
.feature-body {
  height: 100%;
  font-size: var(--text-md);  
  text-align: center;
  letter-spacing: 1px;
  padding: 5%;
}
.home-li-item {
  font-size: var(--text-lg);
  
  text-align: center;
  letter-spacing: 1px;
  color: var(--primary-color);
  display: flex;
  text-align: left;
}
.home-email-input {
  font-size: 1.5vw;
  
  text-align: center;
  letter-spacing: 1px;
  color: var(--primary-color);
  text-align: left;
}
.email-input,
.input-layout-1 {
  width: 100%;
  border-radius: 50px;
  border: 1px solid var(--primary-color);
  padding: 2%;
}
.home-footer {
  height: 50vh;
  width: 100%;
  background: var(--primary-color);
  color: white;
  align-items: center;
  padding: 5%;

  display: grid;
  grid-template-rows: 87% 10%;
  gap: 3%;

  font-size: var(--text-md);
  
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 2%;
}
.home-footer-body {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}
.home-footer-logo-div {
  width: 80%;
  margin: auto;
}
.home-footer-about {
  display: grid;
  align-items: center;
}
.home-login-btn {
  color: #ffffff;
  border: 0px;
  border-radius: 50px;
  padding-left: 2rem;
  padding-right: 2rem;
  background:  var(--primary-color);
}
.home-header {
  background-color: transparent !important;
  transition: background-color 0.3s ease !important;
}
.bg-wt {
  background-color: white !important;
}
.copywirte-div {
  display: flex;
  margin: auto;
}
.privacy-tablink {
  cursor: pointer;
}

.rounded-10 {
  border-radius: 10%;
}

.shadow-2 {
  box-shadow: 0.5rem 0.5rem var(--primary-color) !important;
}

.hero-section-video-div {
    width: 100%;
    height: 100%;
    text-align: center;
    display: grid;
    align-items: center;
    border-radius: 50%;
    margin: auto;
    background: var(--primary-color);
}

.hero-video{
  grid-template-columns: 45% 55% !important;
  background:  linear-gradient(to bottom, #ffffff, var(--primary-color));
}
.bg-primary-gradient{
  background:  linear-gradient(to bottom, #ffffff, var(--primary-color));
}
.promo-video-border{
  border: 5px solid #000000;
}






.home-nav-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5em;
  padding: 10px;
}

.mobile-nav-links {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1000;
}

.mobile-nav-link {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-nav-link:hover {
  background-color: #f1f1f1;
}


.header-layout-1-navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

.navlink-layout-1 {
  grid-template-columns: 20% 60% 20% !important;
}

@media (max-width: 600px) {
  .hero-title {
    width: 80%;
    font-size: 48px;
  }
  .hero-subtitle {
    width: 55%;
    font-size: 16px;
  }
  .hero-cta {
    width: 50%;
  }
  .hero-container {
    grid-template-columns: 1fr !important;
  }
  .home-intro-container {
    grid-template-columns: 1fr !important;
  }
  .home-footer-body {
    grid-template-columns: 1fr !important;
    gap: 1rem !important;
  }
  .features-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .home-right-header {
    display: none !important;
  }
  .home-logo-header {
    justify-content: center !important;
  }
  .home-header {
    grid-template-columns: 90% 10% !important;
  }

  .header-layout-1-navLinks {
    display: none;
  }

  .home-nav-toggle {
    display: block;
  }

  .mobile-nav-links {
    display: flex;
  }
}
@media (max-width: 500px) {
  .hero-container {
    grid-template-columns: 1fr !important;
  }
  .home-intro-container {
    grid-template-columns: 1fr !important;
  }
  .home-footer-body {
    grid-template-columns: 1fr !important;
    gap: 1rem !important;
  }
  .features-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .gameTitle {
    font-size: 8vw !important;
  }
  .subheadline {
    font-size: 3vw !important;
  }
  .btn-layout-2,
  .home-li-item,
  .home-email-input {
    font-size: 3vw !important;
  }
  .section-title,
  .feature-icon-div {
    font-size: 6vw !important;
  }
  .intro-section-body,
  .feature-title {
    font-size: 3.4vw !important;
  }
  .feature-body,
  .home-footer {
    font-size: 2.4vw !important;
  }


  
}
</style>

