import { render, staticRenderFns } from "./confirmation_modal.vue?vue&type=template&id=2b7cb012&scoped=true"
import script from "./confirmation_modal.vue?vue&type=script&lang=js"
export * from "./confirmation_modal.vue?vue&type=script&lang=js"
import style0 from "./confirmation_modal.vue?vue&type=style&index=0&id=2b7cb012&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7cb012",
  null
  
)

export default component.exports