<template>
  <div class="sidebar">
    <div class="logo d-flex" @click='$router.push("/homepage")'>
      <img src="@/assets/logoIcon.png" alt="TeamsMagic" class="sidebar-tm-logo-icon"/>
      <img src="@/assets/loginPageLogo.png" alt="TeamsMagic" class="sidebar-tm-logo"/>
    </div>
    <ul class="sidebar-nav">
      <li class="nav-item" v-for="(item, index) in tabs" :key="index"  :class="{ 'nav-item-disabled': item.disabled }">
        <router-link :to="item.route" class="nav-link"  active-class="active-tab"   :class="{ 'disabled-link': item.disabled }">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
          <i v-if="item.disabled" class="fas fa-lock lock-icon"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { tabs } from "../../mixins/tabs";
export default {
  data() {
    return {
      tabs:tabs.filter((e)=>e.display_Flg=="Y")
    };
  },
};
</script>

<style scoped src="./sidebar.css">

</style>

