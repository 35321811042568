<template>
        <b-modal id="join-modal" ref="join-modal"  centered hide-footer title="Join Room" @hidden="closeModal" no-close-on-backdrop>
          <b-form @submit="submit">
          <b-row class="my-1">
                <b-col >
                <label for="input-none">Full Name :</label>   
                <b-form-input id="input-none" v-model="form.userName" type="text"  :state="null" placeholder="Enter full name" required></b-form-input>
                </b-col>
              </b-row>
           <b-row class="my-1">
                <b-col >
                <label for="input-none">Room Code :</label>   
                <b-form-input id="input-none" v-model="form.roomCode"  :state="null" placeholder="Enter room code" required></b-form-input>
                </b-col>
              </b-row>
              <p v-if="isInvalid" style="color:red;text-align: center;">{{errMsg}}</p>
           <b-button pill class="mt-3 btn-layout-2 color-2 " block size="lg" type="submit">Join Room</b-button>

          </b-form>
        </b-modal>
        
</template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
        isVisible:{
            type:Boolean
        },
        isInvalid:{
            type:Boolean
        },
        errMsg:{
            type:String
        }
    },
    data() {
      return this.initialState();
    },
    emits: ['update:isVisible','handleSubmit'],
    watch: {
    isVisible(newVal) {
    if(newVal){
        this.$refs['join-modal'].show()
     } 
     
    }
  },
    methods: {
      initialState(){
        return{
          form:{
            userName:"",
            roomCode:"",
          }
        }
      },
      resetData() {
        Object.assign(this.$data, this.initialState());
      },
      closeModal() {
        this.resetInvalid();
        this.resetData();
        this.$emit('update:isVisible', false);
      },
      resetInvalid() {
        this.$emit('update:isInvalid', false);
      },
      submit(event){
        event.preventDefault();
        this.$emit('handleSubmit', this.form);
        this.resetInvalid();
      }
    }
  }
  </script>
  
