import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import io from "socket.io-client";
import utils from "./mixins/utils";
import VueMeta from 'vue-meta';

Vue.use(VueMeta);


// function getCode() {
//     return Math.floor(Math.random() * 10000000);
// }

async function initializeSocket() {
    // console.log("get item::", localStorage.getItem("user_socket_id"))
    // if (localStorage.getItem("user_socket_id")) {
    //     let connection = io(process.env.VUE_APP_SERVER_ENDPOINT, {
    //         query: `loggeduser=${localStorage.getItem("user_socket_id")}`,
    //         transports: ["websocket"]
    //     });
    //     Vue.prototype.$socket = connection;
    //     console.log("Socket connection establisted");
    // } else {
        const randomnum = Math.floor(Math.random() * 1000000000); //API promises
        // localStorage.setItem("user_socket_id", randomnum);
        let connection = io(process.env.VUE_APP_SERVER_ENDPOINT, {
            query: `loggeduser=${randomnum}`,
            transports: ["websocket"]
        });
        Vue.prototype.$socket = connection;
        Vue.prototype.$loggeduserId = randomnum;
        console.log("Socket connection establisted");
    // }

}

import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading);


import BlockUI from 'vue-blockui'

Vue.use(BlockUI)



// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

initializeSocket();
new Vue({
    router,
    store,
    mixins: [utils],
    render: (h) => h(App),
}).$mount("#app");